import api from "@/api/workbench/workbench"
import projectApi from "@/api/project/project"
import processApi from "@/api/project/process"

const { getToDoList, getDoneList, getApplyProgress } = api
const { getProjectTodo } = projectApi
const { apiAllProcess } = processApi

const process = {
	namespaced: true,
	state: {
		allToDoNum: 0,
		projectToDoNum: 0,
		doneNum: 0,
		applyNum: 0,
		progressListData: [],
		progressListLoading: false
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		}
	},
	actions: {
		// 获取所有待办数量
		async getAllToDoNum({ commit }) {
			const res = await getToDoList({
				pageNum: 1,
				pageSize: 1,
				status: 1
			})
			if (res) {
				commit("updateState", {
					allToDoNum: res.data.total
				})
			}
		},
		// 获取项目内待办数量
		async getProjectToDoNum({ commit }, projectId) {
			const res = await getProjectTodo({
				id: projectId,
				status: 1,
				pageNum: 1,
				pageSize: 1
			})
			if (res) {
				commit("updateState", {
					projectToDoNum: res.data.total
				})
			}
		},
		// 获取已办数量
		async getDoneNum({ commit }) {
			const res = await getDoneList({
				pageNum: 1,
				pageSize: 1,
				status: 1
			})
			if (res) {
				commit("updateState", {
					doneNum: res.data.total
				})
			}
		},
		// 获取申请数量
		async getApplyNum({ commit }) {
			const res = await getApplyProgress({
				pageNum: 1,
				pageSize: 1,
				status: 1
			})
			if (res) {
				commit("updateState", {
					applyNum: res.data.total
				})
			}
		},
		// 获取流程状态
		async getProgressListData({ commit }, { projectId, processList }) {
			const listData = processList.map(item => ({
				type: item.obj.nickname,
				label: item.obj.name,
				list: []
			}))
			console.log(listData)
			commit("updateState", {
				progressListLoading: true
			})
			const res = await apiAllProcess({
				projectId
			})
			if (res) {
				res.data.forEach(process => {
					const index = listData.findIndex(
						item => item.type === process.category
					)
					const completeNum = process.processes.filter(
						item => !!item.endTime
					).length
					const processingNum = process.processes.length - completeNum
					listData[index].list.push({
						...process,
						color: process.color || "pink", // 流程颜色
						completeNum,
						processingNum
					})
				})
				commit("updateState", {
					progressListData: listData
				})
			}
			commit("updateState", {
				progressListLoading: false
			})
		}
	}
}

export default process
