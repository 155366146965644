import request from "@/utils/request"

export default function commonApi(moduleName) {
	return {
		// 获取列表
		apiGetPage: data =>
			request(`${moduleName}`, {
				params: data
			}),
		// 新增
		apiAdd: data =>
			request(`${moduleName}`, {
				method: "post",
				body: data
			}),
		// 获取详情
		apiGet: data => request(`${moduleName}/${data.id}`, { params: data }),
		// 修改
		apiUpdate: data =>
			request(`${moduleName}/${data.id}`, {
				method: "put",
				body: data
			}),
		// 删除/禁用
		apiDelete: data =>
			request(`${moduleName}/${data.id}`, {
				method: "delete"
			}),
		// 启用
		apiStart: data =>
			request(`${moduleName}/${data.id}/enable`, {
				method: "put"
			})
	}
}
