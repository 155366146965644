import api from "@/api/public"
import settingApi from "@/api/setting/config"

const { publicGetSystemConfig } = api
const { apiGetSetting } = settingApi

const updateProjectListStatus = (commit, status) => {
	commit(
		"project/updateState",
		{
			projectListStatus: status
		},
		{ root: true }
	)
}

const config = {
	namespaced: true,
	state: {
		IS_ONLY_PDF: false,
		IS_OPEN_PROJECT_LIST: false,
		IN_HOSPITAL: 1,
		OUT_HOSPITAL: 1,
		IS_WATER_MARKED: 0,
		IS_FILE_REMIND: 0,
		IS_EMAIL_NOTICE: 0
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		},
		updateConfig: (state, payload) => {
			const newState = Object.assign({}, state)
			payload.forEach(item => {
				if (!item.code && item.data) {
					const arr = JSON.parse(item.data)
					if (Array.isArray(arr)) {
						arr.forEach(val => {
							val.code && (newState[val.code] = val.value)
						})
					}
				} else {
					item.code && (newState[item.code] = item.value)
				}
			})
			Object.assign(state, {
				...newState
			})
		}
	},
	actions: {
		// 获取系统配置
		async getSystemConfig({ commit, state }) {
			const res = await publicGetSystemConfig()
			const resSetting = await apiGetSetting({
				type: "docconfig"
			})
			if (res && res.data) {
				const data1 = JSON.parse(res.data.data)
				const data2 = resSetting.data || []
				const data = [...data1, ...data2]
				commit("updateConfig", data)
				/* const status = state.IS_OPEN_PROJECT_LIST ? 'open' : 'close';
         updateProjectListStatus(commit, status); */
			}
		}
	}
}

export default config
