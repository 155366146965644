import request from "@/utils/request"

/**
 * 公共数据接口
 * 命名方式：驼峰大写命名，以public开头，
 * 注释要提示一些必要信息
 * 如参数名称，或者参数固定值
 * */
export default {
	/**
	 * 获取枚举信息接口
	 * 参数：array keys 枚举的key的数组
	 * 固定值说明：
	 * BULLETIN：公告类型，返回值：1：信息公开，2：通知公告，3：临床试验信息公告
	 * DELETE:是否禁用，返回值：0：启用，1：禁用
	 * */
	publicGetEnumerate: data =>
		request("/common/enumerate", {
			method: "post",
			body: data
		}),
	/**
	 * 获取数据字典列表(树)接口
	 * 参数：
	 * string type 数据字典的类型：
	 * 1：组织类型，2：药品分类，3：耗材分类，4：器械分类，5：医疗类型，6：性别，
	 * 7：学历，8：职称，9：单位，10：产品试验类型，11：给药途径，12：财务费用名称
	 * 24: 流程类型 25： 项目状态类型
	 * string isDel 是否有效 有效：1，无效：0
	 * */
	publicGetDictionaryList: data =>
		request("/common/dictionary/open", {
			params: data
		}),
	/**
	 * 获取组织列表接口
	 * 参数：
	 * string type 组织类型：从数据字典中获取
	 * string queryLike 组织名称
	 * */
	publicGetOrganizationList: data =>
		request("/auth/orgs", {
			params: data
		}),
	/**
	 * 获取部门/科室列表接口
	 * 参数：
	 * string orgId 组织id
	 * string queryLike 部门/科室名称
	 * */
	publicGetDepartmentList: data =>
		request("/auth/departments", {
			params: data
		}),
	/**
	 * 获取用户列表接口
	 * 参数：
	 * string realName 用户姓名
	 * */
	publicGetuserList: data =>
		request("/auth/users", {
			params: data
		}),
	/**
	 * 获取系统配置接口
	 * 参数：
	 * 无
	 * */
	publicGetSystemConfig: () => request("/common/settings/on-off"),
	/**
	 * 登录页 获取系统配置
	 * 参数：
	 * id 系统配置的id
	 * */
	publicGetSysSetting: id => request(`/common/sys-setting/${id}`),

	/**
	 * 根据页面类型获取扩展组件列表
	 */
	publicGetExtraData: data =>
		request("/common/extra-config-data", { method: "get", params: data }),
	// 获取当前环境是 公共环境 还是 环境
	publicGetEnv: () => request("/auth/login-environment", { method: "get" }),
	// 根据别名获取表单组件信息详情
	publicGetComponent: data =>
		request("/common/form-component-alias", {
			method: "get",
			params: data
		})
}
