<template>
	<div class="ctms-uploadProgress">
		<Modal
			v-model="modelVisible"
			title="上传进度"
			:mask-closable="false"
			:footer-hide="true"
			:closable="false"
		>
			<Progress :percent="percent" status="active" />
			<div class="clearfix">
				<div style="float: left">速度：{{ speed }}{{ units }}</div>
				<div style="float: right">预计剩余：{{ resttime }}秒</div>
			</div>
		</Modal>
	</div>
</template>
<script>
import requestProgress from "@/utils/requestProgress"

export default {
	name: "CtmsUploadProgress",
	props: ["url", "uploadParams", "modelVisible"],
	data() {
		return {
			speed: 0,
			units: "",
			resttime: 0,
			percent: 0,
			response: null
		}
	},
	watch: {
		modelVisible() {
			if (this.modelVisible) {
				this.uploadRequest()
			}
		}
	},
	methods: {
		uploadRequest() {
			let ot = new Date().getTime()
			let oloaded = 0
			// 上传中回调
			const onProgress = e => {
				const nt = new Date().getTime()
				const pertime = (nt - ot) / 1000
				const perload = e.loaded - oloaded // 计算该分段上传的文件大小，单位b
				ot = new Date().getTime()
				oloaded = e.loaded // 重新赋值已上传文件大小，用以下次计算
				// 上传速度计算
				let speed = perload / pertime // 单位b/s
				const bspeed = speed
				let units = "b/s" // 单位名称
				if (speed / 1024 > 1) {
					speed /= 1024
					units = "k/s"
				}
				if (speed / 1024 > 1) {
					speed /= 1024
					units = "M/s"
				}
				speed = speed.toFixed(1)
				this.speed = speed
				this.units = units
				// 剩余时间
				this.resttime = ((e.total - e.loaded) / bspeed).toFixed(1)
				this.percent = Math.round((e.loaded / e.total) * 100)
			}
			;(async () => {
				this.response = await requestProgress(
					this.url,
					this.uploadParams,
					onProgress
				)
				this.$emit("closeUploadProgress", this.response)
			})()
		}
	}
}
</script>
<style lang="less" scoped>
.ctms-uploadProgress p {
	font-size: 18px;
}
</style>
