import api from "@/api/project/project"

const {
	apiGet,
	getProjectProcessNum,
	getProjectList,
	apiGetProjTypeList,
	apiGetFollowCheck,
	apiGetCode
} = api

const project = {
	namespaced: true,
	state: {
		projectInfo: {
			projectConfigId: null
		},
		projectTeams: [],
		infoHeight: 50,
		processStatus: {
			START: "notStart",
			ING: "notStart",
			OTHER: "notStart",
			END: "notStart"
		},
		list: [],
		projTypeList: [],
		projectListStatus: "close",
		followCheckData: {},
		orgCode: ""
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		}
	},
	actions: {
		// 获取项目列表
		async getProjectList({ commit }, payload) {
			const res = await getProjectList({ name: payload })
			if (res) {
				commit("updateState", {
					list: res.data
				})
			}
		},
		// 获取 项目类型 列表
		async getProjType({ commit }) {
			const res = await apiGetProjTypeList({ isDesigned: 1 }) // 只显示已配置过表单的项目类型。
			if (res) {
				commit("updateState", {
					projTypeList: res.data || []
				})
			}
		},
		// 获取项目信息
		async getProjectInfo({ commit }, projectId) {
			const res = await apiGet({ id: projectId })
			if (res) {
				commit("updateState", {
					projectInfo: res.data.project,
					projectTeams: res.data.teams
				})
				return true
			}
			return false
		},
		// 跟踪审查
		async getFollowCheck({ commit }, projectId) {
			const res = await apiGetFollowCheck({
				projectId,
				componentAlias: "ethic_approval_date"
			})
			if (res) {
				commit("updateState", {
					followCheckData: res.data
				})
				return true
			}
			return false
		},
		// 编号修改
		async getExistCode({ commit }, projectId) {
			const res = await apiGetCode({
				projectId
			})
			if (res) {
				commit("updateState", {
					orgCode: res.data ? res.data["mechanism_project No"] : ""
				})
				return true
			}
			return false
		},
		// 获取进行中流程的数据（判断各类流程的状态）
		async getProjectProcessNum({ commit, state }, projectId) {
			const res = await getProjectProcessNum({
				projectId
			})
			if (res) {
				let processStatus = Object.assign({}, state.processStatus)
				switch (state.projectInfo.status) {
					case 0:
						processStatus = {
							START: "notStart",
							ING: "notStart",
							OTHER: "notStart",
							END: "notStart"
						}
						break
					case 1:
						processStatus = {
							START: "processing",
							ING: "notStart",
							OTHER: "notStart",
							END: "notStart"
						}
						break
					case 2:
						processStatus = {
							START: "end",
							ING: "notStart",
							OTHER: "notStart",
							END: "notStart"
						}
						break
					case 3:
						processStatus = {
							START: "end",
							ING: "end",
							OTHER: "end",
							END: "notStart"
						}
						break
					default:
						break
				}
				Object.keys(processStatus).forEach(item => {
					if (res.data[item]) {
						if (res.data[item].complete) {
							processStatus[item] = "end"
						}
						if (res.data[item].running) {
							processStatus[item] = "processing"
						}
					}
				})

				commit("updateState", {
					processStatus
				})
			}
		}
	}
}

export default project
