import Vue from "vue"

const filters = {
	// 根据数量截取字符串
	textNowrapByNum: (value, num = 65) => {
		if (!value) {
			return ""
		}
		return value.length > num ? `${value.slice(0, num)}...` : value
	}
}

Object.keys(filters).forEach(filter => {
	Vue.filter(filter, filters[filter])
})
