import { Message, Modal } from "view-design"
import router from "@/router"
import store from "../store/index"
import { addLog, paramsStringify } from "./util"

const REQUEST_TIMEOUT = 60 * 60 // 请求超时时间：秒

// 防止接口多次提示
let isOut = false

// 检测response数据，返回data
export const httpLoad = res => {
	if (res) {
		switch (window.parseInt(res.code)) {
			// 请求成功
			case 0:
				isOut = false
				return res
			// 验证不通过，重新登录
			case 10001:
				if (!isOut) {
					isOut = true
					Message.error("登录过期!")
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
				}
				return false
			// 单点登录
			case 10004:
				if (!isOut) {
					isOut = true
					Message.error(
						"当前账号已在其他地方登陆，您已被迫下线，请注意账号安全!"
					)
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
				}
				return false
			// 修改权限
			case 10005:
				if (!isOut) {
					isOut = true
					Message.error("当前账号权限发生变更，请重新登录!")
					Modal.remove() // 全局关闭对话框
					store.commit("user/clearSession")
					store.commit("permission/clearMenuPermissions", {}, { root: true })
				}
				return false
			// 修改权限
			case 10006:
				if (!isOut) {
					isOut = true
					Message.error("无此项目权限!")
					Modal.remove() // 全局关闭对话框
					router.push("/403")
				}
				return false
			default:
				Message.error(res.message)
				return false
		}
	}
	Message.error("网络错误，请刷新重试！")
	return false
}

function checkStatus(res) {
	if (res.status >= 200 && res.status < 300) {
		return res.response
	}
	if (res.status === 401 || res.status === 403) {
		// 验证不通过，重新登录
		store.dispatch("user/logout")
		return false
	}
	// 请求错误
	const error = new Error(res.statusText)
	error.response = res
	addLog(res.responseURL, {
		status: res.status,
		statusText: res.responseText
	})
	throw error
}

function parseJSON(response) {
	try {
		return JSON.parse(response)
	} catch (e) {
		throw new Error(e)
	}
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

export default function requestProgress(url, opts = {}, onProgress) {
	const { params = {}, headers = {}, method = "post" } = opts
	return new Promise((resolve, reject) => {
		const requestUrl = Object.keys(params).length
			? `${process.env.VUE_APP_REQUEST_BASE_URL + url}?${paramsStringify(params)}`
			: process.env.VUE_APP_REQUEST_BASE_URL + url
		const xhr = new XMLHttpRequest()
		xhr.open(method, requestUrl)
		if (Object.keys(headers).length) {
			Object.keys(headers).forEach(key => {
				xhr.setRequestHeader(key, headers[key])
			})
		}
		xhr.setRequestHeader("token", store.state.user.token)
		xhr.timeout = REQUEST_TIMEOUT * 1000
		xhr.onload = e => {
			resolve(e.target)
		}
		xhr.onerror = () => {
			reject()
		}
		if (xhr.upload && onProgress) {
			xhr.upload.onprogress = onProgress // 上传
		}
		xhr.ontimeout = () => {
			reject(new Error("request timeout"))
		}
		xhr.send(opts.body)
	})
		.then(checkStatus)
		.then(parseJSON)
		.then(data => httpLoad(data))
		.catch(err => {
			console.error(err)
			if (err && err.message === "request timeout") {
				addLog(url, {
					status: "time out",
					statusText: "time out",
					requestTime: `${REQUEST_TIMEOUT}s`
				})
			}
			Message.error({
				content: "网络错误，请刷新重试！",
				duration: 5
			})
		})
}
