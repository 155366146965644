import request from "@/utils/request"
import requestProgress from "@/utils/requestProgress"

const moduleName = "/flowable/todo"

// 获取代办列表
export default {
	// 获取代办列表
	getToDoList: data =>
		request(moduleName, {
			method: "get",
			params: data
		}),
	// 获取审批表单数据
	getExtraInfo: data =>
		request(`${moduleName}/${data.id}/extra-info`, {
			method: "get",
			params: data
		}),
	// 获取已配置表单
	getFormList: data =>
		request("/common/form-file/list", {
			method: "get",
			params: data
		}),
	// 提交待办同意审批
	approve: (id, data) =>
		request(`${moduleName}/${id}/approve`, {
			method: "put",
			body: data
		}),
	// 提交待办拒绝审批
	refuse: (data, toDoId) =>
		request(`${moduleName}/${toDoId}/refuse`, {
			method: "put",
			body: data
		}),
	// 撤回流程
	withdrawal: toDoId =>
		request(`${moduleName}/${toDoId}/withdrawal`, {
			method: "put"
		}),
	// 获取自己在当前项目下的角色列表
	apiGetMyRoles: data =>
		request(`/project/${data.projectId}/team-users/myself-roles`, {
			method: "get"
		}),
	// 提交已办列表
	getDoneList: data =>
		request("/flowable/dealed-todo", {
			method: "get",
			params: data
		}),
	// 提交暂存
	shortSave: (todoId, data) =>
		request(`/flowable/todo/${todoId}/temp-save`, {
			method: "post",
			body: data
		}),
	// 查询流程中所有类型最新的表单
	getAllLastForms: data => request(`/flowable/process/${data.id}/forms`),
	// 查询我的申请
	getApplyProgress: data =>
		request("/flowable/my-started-processes", {
			params: data
		}),
	// 对流程审批文件进行 审批通过/ 取消审批
	apiEnable: data =>
		request(`/project/${data.projectId}/review/file`, {
			body: data,
			method: "post"
		}),
	// 对流程审批文件进行 新增 批注
	addAnnotation: data =>
		request(`/project/${data.projectId}/save/annotation`, {
			body: data,
			method: "post"
		}),
	// 对流程审批文件进行 编辑 批注
	updateAnnotation: data =>
		request(`/project/${data.projectId}/update/annotation`, {
			body: data,
			method: "post"
		}),
	// 删除 流程审批文件批注
	deleteAnnotation: data =>
		request(`/project/${data.projectId}/remove/annotation`, {
			body: data,
			method: "delete"
		}),
	// 获取 流程审批文件批注
	getAnnotationList: data =>
		request(`/project/${data.projectId}/list/annotation/${data.procInstId}`, {
			method: "get"
		}),
	// 获取绑定子流程表单列表
	apiProcessFormList: data =>
		request("/common/form-template/no-page", {
			params: data,
			method: "get"
		}),
	// 获取角色人员列表
	apiGetRoleUsers: data =>
		request(`/project/${data.projectId}/team-users/include-system`, {
			params: data,
			method: "get"
		}),
	// 获取投票二维码code 通过待办ID查询投票信息（投票结果分析）
	apiGetVoteCode: data =>
		request("/s-trial/votes/todo-id-query", {
			params: data,
			method: "get"
		}),
	// 完成投票 结束投票
	apiFinishVote: voteId =>
		request(`/s-trial/vote/${voteId}/finished`, {
			method: "post"
		}),
	// 删除文件
	deleteFile: data =>
		request(`/flowable/todo/${data.todoId}/docfile/${data.docfileId}`, {
			method: "delete"
		}),
	// 单个文件上传，并带有进度条
	uploadFile: (data, onprogress) =>
		requestProgress(
			`/flowable/todo/${data.todoId}/docfiles/mkfile`,
			{
				method: "post",
				body: data.fd
			},
			onprogress
		),
	apiGetProjec: (data, id) =>
		request(`/project/${id}`, {
			method: "get",
			params: data
		})
}
