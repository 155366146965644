import sha256 from "js-sha256"
import SparkMD5 from "spark-md5"
import request from "@/utils/request"
import store from "@/store"
import moment from "moment"

import { getStorage } from "@/utils/storage"

// 动态添加js
export function addJs(url) {
	return new Promise((resolve, reject) => {
		const script = document.createElement("script")
		script.src = url
		script.type = "text/javascript"
		document.body.appendChild(script)
		script.onload = () => {
			resolve()
		}
	})
}

// 序列化数据
export function paramsStringify(jsonData = {}) {
	const paramArray = []
	let data = ""
	Object.keys(jsonData).forEach(i => {
		switch (typeof jsonData[i]) {
			case "string":
				data = jsonData[i].trim()
				break
			case "undefined":
				data = ""
				break
			default:
				data = jsonData[i]
				break
		}
		paramArray.push(`${i}=${encodeURIComponent(data)}`)
	})
	return paramArray.join("&")
}

// 获取url参数
export function getParameterByName(name, url) {
	const newUrl = url || window.location.href
	const newName = name.replace(/[\]]/g, "\\$&")
	const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`)

	const results = regex.exec(newUrl)
	if (!results) return ""
	if (!results[2]) return ""
	return decodeURIComponent(results[2].replace(/\+/g, " "))
}

// 加密数据
export function dataToSha(string) {
	const data = `${string}uy.L_-6q4LY@a25M9R.comGgNAu.8jS@Wo`
	return sha256(sha256(data))
}

// async自动执行方法
export function asyncDo(fn) {
	fn()
}

// 下划线转换驼峰
export function lineToHump(name) {
	return name.replace(/(\w)/g, (all, letter) => letter.toUpperCase())
}

// 驼峰转换下划线
export function humpToUnderLine(name) {
	return name.replace(/([A-Z])/g, "_$1").toLowerCase()
}

// 驼峰转换短横线
export function humpToLine(name) {
	return name.replace(/([A-Z])/g, "-$1").toLowerCase()
}

// 首字母大写
export function firstCharUpperCase(str) {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

// 转换树类型的数据
export function transformTreeData(data) {
	if (data && data.length) {
		return data.map(item =>
			Object.assign(item, {
				children: transformTreeData(item.leaf),
				title: item.name,
				id: item.id,
				key: item.id
			})
		)
	}
	return []
}

// 上传文件计算文件MD5
export function formDataToMD5(file, getMD5Fn) {
	const blobSlice =
		File.prototype.slice ||
		File.prototype.mozSlice ||
		File.prototype.webkitSlice
	// 文件切片大小 2M
	const chunkSize = 2097152
	const chunks = Math.ceil(file.size / chunkSize)
	const spark = new SparkMD5.ArrayBuffer()
	const fileReader = new FileReader()

	let currentChunk = 0

	const loadNext = () => {
		const start = currentChunk * chunkSize
		const end = start + chunkSize >= file.size ? file.size : start + chunkSize

		fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
	}

	fileReader.onload = e => {
		spark.append(e.target.result)
		currentChunk += 1
		if (currentChunk < chunks) {
			loadNext()
		} else if (getMD5Fn && typeof getMD5Fn === "function") {
			getMD5Fn(spark.end())
		}
	}

	fileReader.onerror = () => {
		if (getMD5Fn && typeof getMD5Fn === "function") {
			getMD5Fn(false)
		}
	}

	loadNext()
}

// 转换文件单位
export function transformSizeUnit(size) {
	if (!size || Number.isNaN(size)) {
		return "--"
	}
	if (size >= 1024 * 1024 * 1024) {
		return `${(size / (1024 * 1024 * 1024)).toFixed(2)}GB`
	}
	if (size >= 1024 * 1024) {
		return `${(size / (1024 * 1024)).toFixed(2)}MB`
	}
	if (size >= 1024) {
		return `${(size / 1024).toFixed(2)}KB`
	}
	return `${size}B`
}

// 根据key获取枚举
export function getEnumerateByKey(data, key) {
	if (data && data.length) {
		return data.find(item => item.k === key) || {}
	}
	return {}
}

// 根据参数切割字符串（渲染流程图时用）
export function splitStringByNum(string, num) {
	const len = string.length
	const splitNum = Math.ceil(len / num)
	const result = []
	for (let i = 0; i < splitNum; i += 1) {
		result.push(string.slice(i * num, (i + 1) * num))
	}
	return result
}

// 提取表单数据（审批提交时用）
export function extractFormData(element) {
	const components = element[0].querySelectorAll('[data-type="form-component"]')
	const data = {}
	if (components.length) {
		Array.from(components).forEach(item => {
			const key = item.getAttribute("data-component-code")
			const type = item.getAttribute("data-component-type")
			if (
				[
					"INPUT",
					"TEXTAREA",
					"SELECT",
					"PROJECTACCEPT",
					"PROJECTAPPROVAL",
					"ETHICSACCEPT",
					"ETHICSAPPROVE"
				].indexOf(type.toUpperCase()) > -1
			) {
				data[key] = item.value
			} else if (["checkbox", "radio"].indexOf(type) > -1) {
				data[key] = element[0].querySelector(`[name="${key}"]:checked`)
					? (data[key] = element[0].querySelector(
							`[name="${key}"]:checked`
						).value)
					: ""
			} else if (["signature"].indexOf(type) > -1) {
				data[key] = item.src
			}
		})
	}
	return data
}

// 表单component转换为html的工具方法(审批提交时用)
const transformComponentToHtmlUtilFn = {
	// 将input, textarea, select的值放到span中，并且隐藏
	transformInputComponent: (item, key, type) => {
		// 将值存到span中
		const span = document.createElement("span")
		span.innerText = item.value
		// 通过data-show-type=form-html隐藏控件
		span.setAttribute("data-show-type", "form-html")
		span.setAttribute("data-type", "form-component")
		span.setAttribute("data-component-code", key)
		span.setAttribute("data-component-type", type)
		item.parentNode.insertBefore(span, item)
		// 设置表单组件的属性
		item.setAttribute("data-value", item.value)
		item.setAttribute("class", "component-hidden")
	},
	// 将radio、 checkbox变成不可以点击，并且设置选中的空间checked
	transformRadioComponent: (item, key, cloneEle) => {
		// 将checked, radio转换为readonly
		const componentValue = []
		const checkedComponents = cloneEle.querySelectorAll(
			`[name="${key}"]:checked`
		)
		if (checkedComponents.length) {
			Array.from(checkedComponents).forEach(checkedComponent => {
				componentValue.push(checkedComponent.value)
			})
		}
		const itemComponent = item.querySelector(`[name="${key}"]`)
		if (itemComponent) {
			itemComponent.setAttribute("onclick", "return false;")
			if (
				componentValue.length &&
				componentValue.indexOf(itemComponent.value) > -1
			) {
				itemComponent.setAttribute("checked", "checked")
			} else {
				itemComponent.removeAttribute("checked")
			}
		}
	},
	// 将未签名的占位符删掉
	transformSignatureComoponet: item => {
		const businessState = item.getAttribute("data-business-state")
		// 如果是toDo，则认为没有签名，因此提交之后要把占位符隐藏
		if (businessState === "toDo") {
			// 设置表单组件的属性
			item.setAttribute("class", "component-hidden")
		}
	}
	// 立项号
}

// 表单component转换为html(审批提交时用)
export function transformComponentToHtml(element) {
	const cloneEle = element[0].cloneNode(true)
	// 处理普通组件的dom
	const simpleComponents = cloneEle.querySelectorAll(
		'[data-type="form-component"]'
	)
	if (simpleComponents.length) {
		Array.from(simpleComponents).forEach(item => {
			const key = item.getAttribute("data-component-code")
			const type = item.getAttribute("data-component-type")
			if (
				[
					"INPUT",
					"TEXTAREA",
					"SELECT",
					"PROJECTACCEPT",
					"PROJECTAPPROVAL",
					"ETHICSACCEPT",
					"ETHICSAPPROVE"
				].indexOf(type.toUpperCase()) > -1
			) {
				// input(单行), textarea(多行), select(下拉), projectApproval(立项号)
				transformComponentToHtmlUtilFn.transformInputComponent(item, key, type)
			} else if (["checkbox", "radio"].indexOf(type) > -1) {
				// checkbox(多选), radio(单选)
				transformComponentToHtmlUtilFn.transformRadioComponent(
					item,
					key,
					cloneEle
				)
			} else if (["signature"].indexOf(type) > -1) {
				// signature(签名)
				transformComponentToHtmlUtilFn.transformSignatureComoponet(item)
			}
		})
	}
	// 用了标识此表单是否被使用过
	// （通过数量，可以判断表单使用过几次，暂时没用）
	const oldSpan = document.createElement("span")
	oldSpan.setAttribute("data-form-status-used", "")
	cloneEle.appendChild(oldSpan)
	return cloneEle.innerHTML
}

// 表单html转换为component(审批展示时用)
export function transformHtmlToComponent(html) {
	const div = document.createElement("div")
	div.innerHTML = html
	// 删除原来转换为span的html
	const htmlSpans = div.querySelectorAll('[data-show-type="form-html"]')
	if (htmlSpans.length) {
		Array.from(htmlSpans).forEach(item => {
			item.parentNode.removeChild(item)
		})
	}
	// 将原来隐藏的元素显示出来
	const components = div.querySelectorAll('[data-type="form-component"]')
	if (components.length) {
		Array.from(components).forEach(item => {
			const key = item.getAttribute("data-component-code")
			const type = item.getAttribute("data-component-type")
			if (
				[
					"INPUT",
					"TEXTAREA",
					"SELECT",
					"PROJECTACCEPT",
					"PROJECTAPPROVAL",
					"ETHICSACCEPT",
					"ETHICSAPPROVE"
				].indexOf(type.toUpperCase()) > -1
			) {
				// input(单行), select(下拉), projectApproval(立项号)
				const value = item.getAttribute("data-value")
				item.setAttribute("value", value)
				item.setAttribute("class", "")
			} else if (["textarea"].indexOf(type) > -1) {
				// textarea(多行)
				const value = item.getAttribute("data-value")
				item.setAttribute("class", "")
				Object.assign(item, {
					innerText: value
				})
			} else if (["checkbox", "radio"].indexOf(type) > -1) {
				// checkbox(多选), radio(单选)
				const itemComponent = item.querySelector(`[name="${key}"]`)
				itemComponent.removeAttribute("onclick")
			} else if (["signature"].indexOf(type) > -1) {
				// signature(签名)
				item.setAttribute("class", "")
			}
		})
	}
	return div.innerHTML
}

// 删除html字符串中的name，
// 防止一个页面中有多个相同name的radio（查看时用）
export function delRadioNameInHtml(html) {
	const div = document.createElement("div")
	div.innerHTML = html
	const hasNameComponents = div.querySelectorAll("input[name]")
	if (hasNameComponents.length) {
		hasNameComponents.forEach(item => {
			item.removeAttribute("name")
		})
	}
	return div.innerHTML
}

// 填写表单时，将special-component转换为可填写的表单
export function transformSpecialComponentToForm(html, json) {
	const div = document.createElement("div")
	div.innerHTML = html
	const specialComponents = div.querySelectorAll("[data-special-component]")
	if (specialComponents.length) {
		specialComponents.forEach(item => {
			// 获取特殊组件的类型
			const type = item.getAttribute("data-special-component")
			const code = item.getAttribute("data-component-code")
			if (type === "bind") {
				// 绑定系统数据
				if (json[code].bindChangeable === "1") {
					item.removeAttribute("readonly")
				}
				item.setAttribute("value", json[code].value || "")
			} else if (type === "autoGenerate") {
				// 自动生成立项号
				item.setAttribute("value", json[code].value || "")
			}
		})
	}
	return div.innerHTML
}

// 打印表单时，将input，textarea，select, signature, ProjectApproval都删除（打印时用）
const getPrintFormElement = element => {
	const cloneEle = element.cloneNode(true)
	const components = cloneEle.querySelectorAll('[data-type="form-component"]')
	if (components.length) {
		Array.from(components).forEach(item => {
			const type = item.getAttribute("data-component-type")
			if (
				["input", "textarea", "select", "signature", "ProjectApproval"].indexOf(
					type
				) > -1
			) {
				item.parentNode.removeChild(item)
			}
		})
	}
	return cloneEle
}

// 打印表单时，添加logo
const printFormAppendLogo = (element, approveNo) => {
	const hospitalLogo = getStorage("hospitalLogo")
	if (hospitalLogo) {
		// 创建页眉的div
		const div = document.createElement("div")
		div.style.cssText = `position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    width: 100%;
    text-align: right;
    padding: 0 10px;
    border-bottom: 1px solid #000`
		// 创建立项号
		const p = document.createElement("p")
		p.innerText = approveNo
		p.style.cssText = `height: 48px;
    lineHeight: 25px;
    position: absolute;
    font-size: 16px;
    left: 30px;
    top: 0;`
		// 创建logo
		const img = document.createElement("img")
		img.src = hospitalLogo
		img.style.cssText = "height: 48px;"
		// 插入立项号，logo
		div.appendChild(p)
		div.appendChild(img)
		element.appendChild(div)
	}
	return element.innerHTML
}

// 打印表单
export function printForm(formElement, params = {}) {
	const { isEmpty = true, approveNo = "" } = params
	// 首先获取将input, select, textarea, ProjectApproval 删掉的dom
	const element = isEmpty
		? getPrintFormElement(formElement)
		: formElement.cloneNode(true)
	// 删除name属性，防止radio的name重名
	element.innerHTML = delRadioNameInHtml(element.innerHTML)
	// 添加logo, 立项号
	element.innerHTML = printFormAppendLogo(element, approveNo)
	// 生产随机id
	const id = `printForm_${new Date().getTime()}`
	// 设置获取到的dom的id为随机id
	element.id = id
	element.className = "print-form-area"
	// 由于printarea.js生成打印的时候，会导致页面有稍微的缩放，因此此处做一个放大的功能
	// 另外，如果dom在document中不显示，则打印的时候，无打印内容，所以利用relative和zIndex=-1将dom做视觉隐藏
	Object.assign(element.style, {
		position: "relative",
		zIndex: -1,
		width: "725px",
		paddingTop: "50px",
		height: "auto",
		overflow: "auto"
	})
	document.querySelector("body").appendChild(element)
	// 执行打印功能
	// css中包含了table的重置样式
	window.$(`#${id}`).printArea({
		extraCss: `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/javascript/printArea/PrintArea.min.css`
	})
	// 由于在document中插入了新的dom，所以在打印读取之后，要把这个dom删除掉
	setTimeout(() => {
		document.body.removeChild(document.getElementById(id))
	}, 3000)
	// 返回随机的id，
	return id
}

// 打印简历
export function printResume(formElement, gcpValue) {
	// 复制一份element
	const element = formElement.cloneNode(true)
	// 生产随机id
	const id = `printForm_${new Date().getTime()}`
	// 设置获取到的dom的id为随机id
	element.id = id
	element.querySelector("#gcp").innerHTML = gcpValue
	// 移除table表格的操作列
	const fixedRight = element.querySelector(".ivu-table-fixed-right")
	fixedRight && fixedRight.parentNode.removeChild(fixedRight)
	//  把input和select，datepicker等标签替换为p标签，可以换行显示
	const pritnEles = element.getElementsByClassName("print-item")
	for (let i = 0; i < pritnEles.length; i++) {
		const newNode = document.createElement("p")
		newNode.style.cssText = "white-space: normal;word-break:break-all;"
		const inputEl = pritnEles[i].querySelector(".ivu-input")
		const dataValue = inputEl && inputEl.value ? inputEl.value : "--"
		newNode.innerText = dataValue
		pritnEles[i].innerHTML = ""
		pritnEles[i].appendChild(newNode)
	}
	// 另外，如果dom在document中不显示，则打印的时候，无打印内容，所以利用relative和zIndex=-1将dom做视觉隐藏
	Object.assign(element.style, {
		position: "relative",
		zIndex: -1,
		width: "100%",
		height: "auto",
		overflow: "auto"
	})
	document.querySelector("body").appendChild(element)
	// 执行打印功能
	// css中包含了table的重置样式
	const extraCssList = [
		`${process.env.VUE_APP_PUBLIC_BASE_URL}resource/javascript/printArea/printResume.css`,
		`${process.env.VUE_APP_PUBLIC_BASE_URL}resource/javascript/printArea/iview.css`
	]
	window.$(`#${id}`).printArea({
		extraCss: extraCssList.join(",")
	})
	// 由于在document中插入了新的dom，所以在打印读取之后，要把这个dom删除掉
	setTimeout(() => {
		document.body.removeChild(document.getElementById(id))
	}, 3000)
}

// 下载base64图片
// base64转blob
const base64ToBlob = code => {
	const parts = code.split(";base64,")
	const contentType = parts[0].split(":")[1]
	const raw = window.atob(parts[1])
	const rawLength = raw.length

	const uInt8Array = new Uint8Array(rawLength)

	for (let i = 0; i < rawLength; i += 1) {
		uInt8Array[i] = raw.charCodeAt(i)
	}
	return new Blob([uInt8Array], { type: contentType })
}

// 下载
export function downloadFile(fileName, content) {
	const aLink = document.createElement("a")
	const blob = base64ToBlob(content) // new Blob([content]);

	const evt = document.createEvent("HTMLEvents")
	evt.initEvent("click", true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
	aLink.download = fileName
	aLink.href = URL.createObjectURL(blob)

	// aLink.dispatchEvent(evt);
	aLink.click()
}

// 将前端log添加到后端log
export const addLog = (url, errorObj) => {
	// 如果报错的就是添加log的接口
	// 那基本可以说明是服务器挂了
	// 所以就不需要再请求了
	if (url.indexOf("/web-log") === -1) {
		request("/web-log", {
			method: "post",
			body: {
				text: JSON.stringify({
					...errorObj,
					url,
					appVersion: window.navigator.appVersion,
					language: window.navigator.language,
					userAgent: window.navigator.userAgent,
					userInfo: store.state.user.info,
					time: moment().format("YYYY-MM-DD HH:mm:ss")
				})
			}
		})
	}
}

export const getDaysInterval = (date1, date2) => {
	if (!date1 || !date2) {
		return null
	}
	const date1Str = date1.split("-") // 将日期字符串分隔为数组,数组元素分别为年.月.日
	// 根据年 . 月 . 日的值创建Date对象
	const date1Obj = new Date(date1Str[0], date1Str[1] - 1, date1Str[2])
	const date2Str = date2.split("-")
	const date2Obj = new Date(date2Str[0], date2Str[1] - 1, date2Str[2])
	const t1 = date1Obj.getTime()
	const t2 = date2Obj.getTime()
	const dateTime = 1000 * 60 * 60 * 24 // 每一天的毫秒数
	// 计算出两个日期的天数差
	return Math.floor((t2 - t1) / dateTime)
}

export const dateFormate = (date, fmt) => {
	if (typeof date === "string") {
		date = new Date(date)
	}
	const o = {
		"M+": date.getMonth() + 1, // 月份
		"d+": date.getDate(), // 日
		"h+": date.getHours(), // 小时
		"m+": date.getMinutes(), // 分
		"s+": date.getSeconds(), // 秒
		"q+": Math.floor((date.getMonth() + 3) / 3), // 季度
		S: date.getMilliseconds() // 毫秒
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			`${date.getFullYear()}`.substr(4 - RegExp.$1.length)
		)
	}
	for (const k in o) {
		if (new RegExp(`(${k})`).test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
			)
		}
	}
	return fmt
}
