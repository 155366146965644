// 基础路由，不需要权限控制
export const baseRouterMap = [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/Login/index.vue")
	},
	{
		path: "/auth/third-party-login",
		name: "loginShrtyy",
		component: () => import("@/views/Login/Shetyylogin.vue")
	},
	{
		path: "/403",
		name: "Exception-403",
		component: () =>
			import("@/components/GlobalPage/GlobalException/Exception-403.vue")
	},
	{
		path: "/404",
		name: "Exception-404",
		component: () =>
			import("@/components/GlobalPage/GlobalException/Exception-404.vue")
	},
	{
		path: "/register",
		name: "register",
		component: () => import("@/views/Register/Register.vue")
	},
	{
		path: "/password/reset",
		name: "resetPassword",
		component: () => import("@/views/password/passwordNew.vue")
	},
	{
		path: "/XHregister",
		name: "XHregister",
		component: () => import("@/views/Register/XinhuaRegister.vue") // 医院注册
	},
	{
		path: "/del-process",
		name: "delProcess",
		component: () => import("@/views/Setting/Process/DelProcess.vue")
	},
	// 项目信息
	{
		path: "/projectInfo/:proId",
		name: "projectInfo",
		component: () => import("@/views/Project/ViewProject.vue")
	},
	// 咨询申请 单页面
	{
		path: "/c",
		name: "shyfsPage",
		component: () => import("@/views/Consult/ConsultManager/ShyfsPage.vue")
	},
	// 咨询申请 单页面
	{
		path: "/a",
		name: "consultAdd",
		component: () => import("@/views/Consult/ConsultManager/ConsultAddPage.vue")
	},
	// 咨询申请完成 单页面
	{
		path: "/f",
		name: "consultFillIn",
		component: () => import("@/views/Consult/ConsultManager/FullFillInPage.vue")
	},
	// 手机端投票登录
	{
		path: "/vote/mobile/login",
		name: "voteMobileLogin",
		component: () => import("@/views/Project/Vote/mobile/Login.vue")
	},
	// 手机端投票
	{
		path: "/vote/mobile/vote",
		name: "mobileVote",
		component: () => import("@/views/Project/Vote/mobile/Vote.vue")
	},
	// 手机端投票成功
	{
		path: "/mobile/voteSuccess",
		name: "mobileVoteSuccess",
		component: () => import("@/views/Project/Vote/mobile/VoteSuccess.vue")
	},
	// 手机端投票结果
	{
		path: "/mobile/voteResult",
		name: "mobileVoteResult",
		component: () => import("@/views/Project/Vote/mobile/VoteResult.vue")
	},
	// 手机端 伦理上会投票登录
	{
		path: "/ethical/mobile/login",
		name: "mobileEthicalLogin",
		component: () => import("@/views/EthicalMeeting/mobile/Login.vue")
	},
	// 手机端 伦理上会投票
	{
		path: "/ethical/mobile/vote",
		name: "mobileEthicalVote",
		component: () => import("@/views/EthicalMeeting/mobile/Vote.vue")
	},
	// onlyoffice 在线批注文档
	{
		path: "/only-office",
		name: "onlyOffice",
		component: () => import("@/views/EthicalMeeting/viewOnlyOffice.vue")
	}
]

// 提醒： 如果有子路由，子路由的name里一定要包含主路由的name，否则主路由不会高亮显示。
// 系统路由
export const systemRouterMap = [
	{
		path: "/",
		name: "index",
		component: () => import("@/components/GlobalPage/GlobalLayout.vue"),
		meta: { title: "首页" },
		children: [
			// 首页
			{
				path: "/navigation",
				name: "navigation",
				component: () => import("@/views/Navigation/index.vue"),
				meta: {
					title: "首页",
					icon: "icon-gongzuotai",
					activeIcon: "icon-gongzuotaixuanzhong",
					permission: "menu_workbench"
				}
			},
			// 工作台
			// {
			//   path: '/workbench',
			//   name: 'workbench',
			//   component: () => import('@/views/Workbench/index.vue'),
			//   meta: {
			//     title: '工作台',
			//     icon: 'icon-gongzuotai',
			//     activeIcon: 'icon-gongzuotaixuanzhong',
			//     permission: 'menu_workbench',
			//   },
			// },
			// 项目管理
			{
				path: "/project",
				name: "project",
				component: () => import("@/views/Project/Index"),
				meta: {
					title: "项目管理",
					icon: "icon-xiangmuguanli",
					activeIcon: "icon-xiangmuguanlixuanzhong",
					permission: "menu_project"
				}
			},
			// 研究成果管理
			{
				path: "/achievement",
				name: "Achievement",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "研究成果",
					icon: "icon-yanjiuchengguo",
					activeIcon: "icon-yanjiuchengguo-h",
					permission: "menu_research"
				},
				children: [
					{
						path: "/achievement/projsearch",
						name: "achievementProjSearch",
						component: () => import("@/views/Achievement/Index.vue"),
						meta: {
							title: "成果填报情况",
							permission: "menu_research_project"
						}
					},
					// 研究成果表单配置 使用嵌套路由展现平行路由效果
					{
						path: "/achievement/typesearch",
						name: "achievementTypeSearch",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "按成果类型查阅",
							permission: "menu_research_type",
							mode: "single"
						},
						redirect: "/achievement/typesearch/list",
						children: [
							{
								path: "/achievement/typesearch/list",
								name: "achievementTypeSearchList",
								component: () => import("@/views/Achievement/TypeIndex.vue"),
								meta: {
									title: "成果类型列表",
									permission: "menu_research_type",
									keepAlive: true
								}
							},
							{
								path: "/achievement/typesearch/pro",
								name: "achievementTypeSearchPro",
								component: () => import("@/views/Achievement/TypeDetail.vue"),
								meta: {
									title: "成果类型项目列表",
									permission: "menu_research_type"
								}
							}
						]
					}
				]
			},
			// 专项功能
			{
				path: "/special",
				name: "Special",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "专项功能",
					icon: "icon-zhuanxianggongneng",
					activeIcon: "icon-zhuanxianggongnengxuanzhong",
					permission: "menu_special_function"
				},
				children: [
					{
						path: "/special/list",
						name: "SpecialHome",
						component: () => import("@/views/Subject/SubjectList/Index.vue"),
						meta: {
							title: "受试者预筛库",
							permission: "menu_subject_patients"
						}
					},
					{
						path: "/special/proexpr",
						name: "SpecialPrjExpe",
						component: () => import("@/views/Subject/ProjExperience/Index.vue"),
						meta: {
							title: "受试者项目经历",
							permission: "menu_subject_projects"
						}
					},
					{
						path: "/special/checkbatch",
						name: "Specialcheckbatch",
						component: () => import("@/views/Subject/checkbatch/Index.vue"),
						meta: {
							title: "受试者查重批次",
							permission: "menu_subject_check"
						}
					},
					// 伦理上会管理
					{
						path: "/special/ethicalMeetinglist",
						name: "SpecialEthicalMeetingList",
						component: () => import("@/views/EthicalMeeting/Index"),
						meta: {
							title: "伦理上会",
							permission: "menu_ethic_meeting",
							keepAlive: true
						},
						children: [
							{
								path: "/special/inMeet",
								name: "SpecialInMeet",
								component: () => import("@/views/EthicalMeeting/InMeet"),
								meta: {
									title: "入会",
									permission: "menu_ethic_meeting"
								}
							}
						]
					},

					// 会议管理
					{
						path: "/special/meeting",
						name: "Specialmeeting",
						component: () => import("@/views/Meeting/Index.vue"),
						meta: {
							title: "会议管理",
							icon: "icon-huiyiguanli",
							activeIcon: "icon-huiyiguanlixuanzhong",
							permission: "menu_meeting"
						}
					},
					// 培训记录
					{
						path: "/special/train",
						name: "Specialtrain",
						component: () => import("@/views/Train/Index"),
						meta: {
							title: "培训管理",
							icon: "icon-peixunguanli",
							activeIcon: "icon-peixunguanlixuanzhong",
							permission: "menu_training"
						}
					},
					// 证书管理
					{
						path: "/special/CertManageList",
						name: "SpecialCertManageList",
						component: () => import("@/views/CertManage/Index.vue"),
						meta: {
							title: "证书管理",
							permission: "menu_subject_certificate"
						}
					},
					{
						path: "/special/user",
						name: "SpecialUser",
						component: () => import("@/views/CertManage/IndexUser.vue"),
						meta: {
							title: "持证人员管理",
							permission: "menu_subject_certificate_user"
						}
					},
					// 药房管理
					{
						path: "/special/pharmacy",
						name: "SpecialPharmacy",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "药房管理",
							permission: "menu_pharmacy",
							keepAlive: true
						},
						redirect: "/special/pharmacyList",
						children: [
							{
								path: "/special/pharmacyList",
								name: "SpecialPharmacyList",
								component: () => import("@/views/Pharmacy/List.vue"),
								meta: {
									title: "药房管理",
									permission: "menu_pharmacy",
									keepAlive: true
								}
							},
							{
								path: "/special/pharmacyList/humiture",
								name: "SpecialPharmacyListHumiture",
								component: () => import("@/views/Pharmacy/Humiture/Index.vue"),
								meta: {
									title: "温湿度详情",
									permission: "menu_pharmacy"
								}
							},
							{
								path: "/special/pharmacyList/detail",
								name: "SpecialPharmacyListDetail",
								component: () => import("@/views/Pharmacy/Detail.vue"),
								meta: {
									title: "药房详情",
									permission: "menu_pharmacy",
									keepAlive: true
								}
							},
							{
								path: "/special/detail/drugInventory",
								name: "SpecialPharmacyListDetailDrugInventory",
								component: () =>
									import(
										"@/views/Project/Goods/Drug/Inventory/Medicine/Index.vue"
									),
								meta: {
									title: "药物详情",
									permission: "menu_pharmacy"
								}
							},
							{
								path: "/special/detail/meterialInventory",
								name: "SpecialPharmacyListDetailMeterialInventory",
								component: () =>
									import(
										"@/views/Project/Goods/Drug/Inventory/Meterial/Index.vue"
									),
								meta: {
									title: "耗材详情",
									permission: "menu_pharmacy"
								}
							},
							{
								path: "/special/inventory",
								name: "SpecialPharmacyListInventory",
								component: () => import("@/views/Pharmacy/Inventory/Index.vue"),
								meta: {
									title: "库存详情",
									permission: "menu_pharmacy"
								}
							}
						]
					},

					// 仪器设备管理
					{
						path: "/special/instrument",
						name: "Specialinstrument",
						component: () => import("@/views/Instrument/Index.vue"),
						meta: {
							title: "仪器设备管理",
							icon: "icon-huiyiguanli",
							activeIcon: "icon-huiyiguanlixuanzhong",
							permission: "menu_equipment"
						}
					}
				]
			},
			// 统计报表中心
			{
				path: "/report",
				name: "Report",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "统计报表中心",
					icon: "icon-baobiaozhongxin",
					activeIcon: "icon-baobiaozhongxinxuanzhong",
					permission: "menu_report"
				},
				children: [
					// 项目流程报表
					{
						path: "/report/project-progress",
						name: "reportProjectProgress",
						component: () => import("@/views/Report/ProjectProgress"),
						meta: { title: "项目进度报表", permission: "menu_report_process" }
					},
					// 项目进度报表
					{
						path: "/report/project-schedule",
						name: "reportProjectSchedule",
						component: () => import("@/views/Report/ProjectSchedule"),
						meta: {
							title: "项目流程进度报表",
							permission: "menu_report_flowprocess"
						}
					},
					// 项目信息报表
					{
						path: "/report/project-info",
						name: "reportProjectInfo",
						component: () => import("@/views/Report/ProjectInfo"),
						meta: {
							title: "项目信息报表",
							permission: "menu_report_projectinfo"
						}
					},
					// 合同报表
					{
						path: "/report/project-contract",
						name: "reportProjectContract",
						component: () => import("@/views/Report/ContractReport"),
						meta: { title: "合同报表", permission: "menu_report_contract" }
					},
					// 机构经费报表
					{
						path: "/report/project-orgFunds",
						name: "reportProjectOrgFunds",
						component: () => import("@/views/Report/OrgFundsReport"),
						meta: {
							title: "机构经费报表",
							permission: "menu_report_outlay_mechanism"
						}
					},
					// 伦理经费报表
					{
						path: "/report/project-ethicsFunds",
						name: "reportProjectEthicsFunds",
						component: () => import("@/views/Report/EthicsFundsReport"),
						meta: {
							title: "伦理经费报表",
							permission: "menu_report_outlay_ethic"
						}
					},
					// 伦理项目清单报表
					{
						path: "/report/ethicalProjects",
						name: "reportEthicalProjects",
						component: () => import("@/views/Report/EthicalProReport"),
						meta: {
							title: "伦理项目清单报表",
							permission: "menu_report_ethic_project"
						}
					},
					// 结题统计报表
					{
						path: "/report/conclusion",
						name: "reportConclusion",
						component: () => import("@/views/Report/ConclusionReport"),
						meta: {
							title: "结题统计报表",
							permission: "menu_report_conclusion"
						}
					},
					// 档案统计报表
					{
						path: "/report/archives",
						name: "reportArchives",
						component: () => import("@/views/Report/ArchivesReport"),
						meta: {
							title: "档案统计报表",
							permission: "menu_report_file_manage"
						}
					},
					// 月度财务报表
					{
						path: "/report/monthly-statement",
						name: "reportProjectMonthlyFinancialStatement",
						component: () => import("@/views/Report/MonthlyFinancialStatement"),
						meta: {
							title: "月度财务报表",
							permission: "menu_report_monthlyfinance"
						}
					},
					// 年度财务报表
					{
						path: "/report/annual-statement",
						name: "reportProjectAnnualFinancialStatement",
						component: () => import("@/views/Report/AnnualFinancialStatement"),
						meta: {
							title: "年度财务报表",
							permission: "menu_report_yearfinance"
						}
					}
					// 咨询报表
					// {
					//   path: '/report/consulting-report',
					//   name: 'ConsultingReport',
					//   component: () => import('@/views/Report/ConsultingReport'),
					//   meta: { title: '咨询报表', permission: 'menu_report_consultation' },
					// },
					// 遗传办 资源样本核查报表
					// {
					//   path: '/report/genetic-source',
					//   name: 'GeneticSourceReport',
					//   component: () => import('@/views/Report/GeneticSource'),
					//   meta: { title: '人遗样本核查信息表', permission: 'menu_hgc_resource_report' },
					// },
					// 遗传办 行政许可自查报表
					// {
					//   path: '/report/genetic-license',
					//   name: 'GeneticLicenseReport',
					//   component: () => import('@/views/Report/GeneticLicense'),
					//   meta: { title: '人遗行政许可自查表', permission: 'menu_hgc_admin_report' },
					// },
				]
			},
			// 公共资源中心
			{
				path: "/resource",
				name: "Resource",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "公共资源中心",
					icon: "icon-ziliaozhongxin",
					activeIcon: "icon-ziliaozhongxinxuanzhong",
					permission: "menu_document"
				},
				children: [
					// 资料中心
					{
						path: "/resource/common",
						name: "ResourceCommon",
						component: () => import("@/views/Document/Index.vue"),
						meta: {
							title: "资料中心",
							permission: "menu_document"
						}
					},
					// 遗传办资料 通过嵌套路由展现平行效果
					{
						path: "/resource/genetic",
						name: "ResourceGenetic",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "遗传办资料",
							permission: "menu_document",
							keepAlive: true
						},
						redirect: "/resource/genetic",
						children: [
							{
								path: "/resource/genetic",
								name: "ResourceGeneticA",
								component: () => import("@/views/Document/GeneticProject.vue"),
								meta: {
									title: "遗传办资料",
									permission: "menu_document",
									keepAlive: true
								}
							},
							{
								path: "/resource/genetic/list/:projectId",
								name: "ResourceGeneticList",
								component: () => import("@/views/Document/Genetic.vue"),
								meta: {
									title: "遗传办文档库",
									permission: "menu_document"
								}
							}
						]
					},

					// 公告管理
					{
						path: "/resource/notice",
						name: "Resourcenotice",
						component: () => import("@/views/Notice/Index"),
						meta: {
							title: "公告管理",
							icon: "icon-gonggaoguanli",
							activeIcon: "icon-gonggaoguanlixuanzhong",
							permission: "menu_notice"
						}
					}
				]
			},
			// 账号管理
			{
				path: "/account",
				name: "Account",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "账号管理",
					icon: "icon-shoushizheku",
					activeIcon: "icon-shoushizhekuxuanzhong",
					permission: "menu_account_manage"
				},
				children: [
					{
						path: "/account/organization",
						name: "AccountOrganization",
						component: () => import("@/views/Setting/Organization/index.vue"),
						meta: {
							title: "组织管理",
							permission: "menu_setting_org"
						}
					},
					{
						path: "/account/manager",
						name: "AccountManager",
						component: () =>
							import("@/views/Setting/Organization/SetManager.vue"),
						meta: {
							title: "维护组织信息",
							// 特殊权限，详见src/store/modules/permission.js
							permission: "menu_setting_organization_set"
						}
					},
					{
						path: "/account/department",
						name: "AccountDepartment",
						component: () => import("@/views/Setting/Department/index.vue"),
						meta: {
							title: "科室/部门管理",
							permission: "menu_setting_dept"
						}
					},
					{
						path: "/account/staff",
						name: "AccountStaff",
						component: () => import("@/views/Setting/Staff/index.vue"),
						meta: {
							title: "用户管理",
							permission: "menu_setting_user"
						}
					},
					{
						path: "/account/userAndProject",
						name: "AccountUserAndProject",
						component: () => import("@/views/Setting/UserAndProject/index.vue"),
						meta: {
							title: "用户项目管理",
							permission: "menu_setting_user"
						}
					},
					{
						path: "/account/role",
						name: "AccountRole",
						component: () => import("@/views/Setting/Role/index.vue"),
						meta: {
							title: "角色管理",
							permission: "menu_setting_auth"
						}
					}
				]
			},

			// SUSAR
			// {
			//   path: '/susar',
			//   name: 'Susar',
			//   component: () => import('@/views/SUSAR/Index.vue'),
			//   meta: {
			//     title: '院外SUSAR',
			//     icon: 'icon-SUSAR1',
			//     activeIcon: 'icon-SUSAR-h',
			//     permission: 'menu_susar',
			//   },
			// },
			// 临床研究咨询
			// {
			//   path: '/consult',
			//   name: 'consult',
			//   component: () => import('@/components/Layouts/RouteView.vue'),
			//   meta: {
			//     title: '临床研究咨询',
			//     icon: 'icon-linchuangyanjiuzixun',
			//     activeIcon: 'icon-linchuangyanjiuzixun-xuanzhong',
			//     permission: 'menu_consultation',
			//   },
			//   children: [
			//     {
			//       path: '/consult/list',
			//       name: 'consultList',
			//       component: () => import('@/views/Consult/ConsultManager/index.vue'),
			//       meta: {
			//         title: '咨询管理',
			//         permission: 'menu_setting_consultation',
			//       },
			//     },
			//     {
			//       path: '/consult/document',
			//       name: 'consultDocument',
			//       component: () => import('@/views/Consult/Document/index.vue'),
			//       meta: {
			//         title: '咨询文档库',
			//         permission: 'menu_document_consultation',
			//       },
			//     },
			//   ],
			// },
			// 人员排班 不在菜单控制里控制权限，在用户管理列表，修改用户里面，有个是否排班负责人，来控制。
			// {
			//   path: '/staffScheduling',
			//   name: 'staffScheduling',
			//   component: () => import('@/views/StaffScheduling/Index.vue'),
			//   meta: {
			//     title: '人员排班',
			//     icon: 'icon-ziliaozhongxin',
			//     activeIcon: 'icon-ziliaozhongxinxuanzhong',
			//     permission: 'menu_schedule',
			//   },
			// },
			// pacs
			// {
			//   path: '/pacs',
			//   name: 'Pacs',
			//   component: () => import('@/views/PACS/index.vue'),
			//   meta: {
			//     title: 'PACS',
			//     icon: 'icon-pacs',
			//     activeIcon: 'icon-pacsxuanzhong',
			//     permission: 'menu_pacs',
			//   },
			// },
			// 个人信息
			{
				path: "/personal",
				name: "personal",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "个人信息",
					icon: "icon-gerenxinxi",
					activeIcon: "icon-gerenxinxixuanzhong",
					// permission: 'menu_personal', // 真的权限项
					// 不希望展示个人信息模块，在左侧菜单栏里，因此写个假的 permission。需要路由，因此不能直接注释掉
					permission: "menu_personal_hide" // 假的权限项
				},
				children: [
					// 个人基本信息
					{
						path: "/personal/index",
						name: "personalIndex",
						component: () => import("@/views/Personal/Info/Info.vue"),
						meta: {
							title: "基本信息",
							permission: "menu_personal_info"
						}
					},
					// 个人简历
					{
						path: "/personal/info",
						name: "personalInfo",
						component: () => import("@/views/Personal/Info/Index.vue"),
						meta: { title: "个人简历", permission: "menu_personal_cv" }
					},
					// 个人签名
					{
						path: "/personal/signature",
						name: "personalSignature",
						component: () => import("@/views/Personal/Signature/Index.vue"),
						meta: { title: "个人签名", permission: "menu_personal_sign" }
					}
				]
			},
			// 操作视频
			// {
			//   path: '/guide',
			//   name: 'guide',
			//   component: () => import('@/views/OperationGuide/Index'),
			//   meta: {
			//     title: '操作视频',
			//     icon: 'icon-caozuoshipin',
			//     activeIcon: 'icon-caozuoshipin-xuanzhong',
			//     permission: 'menu_guidance', // 权限未设置
			//   },
			// },
			// 系统设置
			{
				path: "/setting",
				name: "setting",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "系统设置",
					icon: "icon-shezhi",
					activeIcon: "icon-shezhixuanzhong",
					permission: "menu_setting"
				},
				children: [
					{
						path: "/setting/dictionary",
						name: "settingDictionary",
						component: () => import("@/views/Setting/Dictionary/index.vue"),
						meta: {
							title: "数据字典",
							permission: "menu_setting_dictionary"
						}
					},
					{
						path: "/setting/process",
						name: "settingProcess",
						component: () => import("@/views/Setting/Process/index.vue"),
						meta: {
							title: "流程设计",
							permission: "menu_setting_process"
						}
					},
					{
						path: "/setting/form",
						name: "settingForm",
						component: () => import("@/views/Setting/Form/index.vue"),
						meta: {
							title: "表单设计",
							permission: "menu_setting_form"
						}
					},
					{
						path: "/setting/config",
						name: "settingConfig",
						component: () => import("@/views/Setting/Config/Index.vue"),
						meta: {
							title: "系统参数",
							permission: "menu_setting_config"
						}
					},
					{
						path: "/setting/formItem",
						name: "formItem",
						component: () => import("@/views/Setting/FormItem/Index.vue"),
						meta: {
							title: "表单配置项",
							permission: "menu_setting_config"
						}
					},
					{
						path: "/setting/ConfigCode",
						name: "ConfigCode",
						component: () => import("@/views/Setting/ConfigCode/Index.vue"),
						meta: {
							title: "配置项编码",
							permission: "menu_setting_config"
						}
					},
					{
						path: "/setting/formTemplate",
						name: "setFormTemplate",
						component: () => import("@/views/Setting/formTemplate.vue"),
						meta: {
							title: "表单模板",
							permission: "menu_setting_config"
						}
					},
					{
						path: "/setting/docConfig",
						name: "docConfig",
						component: () => import("@/views/Setting/DocConfig/Index.vue"),
						meta: {
							title: "文档配置",
							permission: "menu_setting_config"
						}
					},
					// {
					//   path: '/setting/switch-config',
					//   name: 'switchConfig',
					//   component: () => import('@/views/Setting/SwitchConfig/Index.vue'),
					//   meta: {
					//     title: '开关配置',
					//     permission: 'menu_switch_config',
					//   },
					// },
					// 项目表单配置 使用嵌套路由展现平行路由效果
					{
						path: "/setting/project-form",
						name: "settingProjectForm",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "项目表单配置",
							permission: "menu_setting_config",
							mode: "single"
						},
						redirect: "/setting/project-form/list",
						children: [
							{
								path: "/setting/project-form/list",
								name: "settingProjectFormList",
								component: () =>
									import(
										"@/views/Setting/ProjectFormSetting/ProjectFormList.vue"
									),
								meta: {
									title: "项目表单列表",
									permission: "menu_setting_config",
									keepAlive: true
								}
							},
							{
								path: "/setting/project-form/config",
								name: "settingProjectFormConfig",
								component: () =>
									import("@/views/Setting/ProjectFormSetting/index.vue"),
								meta: {
									title: "项目表单配置",
									permission: "menu_setting_config"
								}
							}
						]
					},
					// 研究成果表单配置 使用嵌套路由展现平行路由效果
					{
						path: "/setting/achieve-form",
						name: "settingAchieveForm",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "研究成果表单配置",
							permission: "menu_research_config",
							mode: "single"
						},
						redirect: "/setting/achieve-form/list",
						children: [
							{
								path: "/setting/achieve-form/list",
								name: "settingAchieveFormList",
								component: () =>
									import(
										"@/views/Setting/AchievementFormSetting/AchieveFormList.vue"
									),
								meta: {
									title: "研究成果表单列表",
									permission: "menu_research_config",
									keepAlive: true
								}
							},
							{
								path: "/setting/achieve-form/config",
								name: "settingAchieveFormConfig",
								component: () =>
									import("@/views/Setting/AchievementFormSetting/index.vue"),
								meta: {
									title: "研究成果表单配置",
									permission: "menu_research_config"
								}
							}
						]
					}
				]
			},
			// 系统操作记录
			{
				path: "/log",
				name: "log",
				component: () => import("@/views/Log/Index"),
				meta: {
					title: "系统操作记录",
					icon: "icon-xitongcaozuojilu",
					activeIcon: "icon-xitongcaozuojiluxuanzhong",
					permission: "menu_log"
				}
			}
		]
	}
]

// 项目路由
export const projectRouterMap = [
	{
		path: "/project/:projectId",
		name: "projectIndex",
		component: () => import("@/components/GlobalPage/GlobalLayout.vue"),
		meta: { title: "项目管理" },
		children: [
			// 项目预览
			{
				path: "/project/:projectId/workbench",
				name: "projectWorkbench",
				component: () => import("@/views/Project/Info.vue"),
				meta: {
					title: "项目详细信息",
					icon: "icon-xiangmuyulan",
					activeIcon: "icon-xiangmuyulanxuanzhong",
					permission: "menu_project_workbench"
				}
			},
			{
				path: "/project/:projectId/ProjectFormTemplate",
				name: "ProjectFormTemplate",
				component: () => import("@/views/Project/formTemplate/Index.vue"),
				meta: {
					title: "表单管理",
					icon: "icon-xiangmuyulan",
					activeIcon: "icon-xiangmuyulanxuanzhong",
					permission: "menu_project_form_template"
				}
			},
			// 流程管理
			{
				path: "/project/:projectId/process",
				name: "projectProcess",
				component: () => import("@/views/Project/Process/index.vue"),
				meta: {
					title: "流程管理",
					icon: "icon-liuchengguanli",
					activeIcon: "icon-liuchengguanlixuanzhong",
					permission: "menu_project_process"
				}
			},
			// 团队管理
			{
				path: "/project/:projectId/team",
				name: "projectTeam",
				component: () => import("@/views/Project/Team/Team.vue"),
				meta: {
					title: "团队管理",
					icon: "icon-tuanduiguanli",
					activeIcon: "icon-tuanduiguanlixuanzhong",
					permission: "menu_project_team"
				}
			},
			// 项目文档
			{
				path: "/project/:projectId/document",
				name: "projectDocument",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "流程文档库",
					icon: "icon-xiangmuwendangku",
					activeIcon: "icon-xiangmuwendangkuxuanzhong",
					permission: "menu_project_document"
				},
				children: [
					{
						path: "/project/:projectId/document/process",
						name: "projectDocumentProcess",
						component: () => import("@/views/Project/processDoc/Document.vue"),
						meta: {
							title: "流程文档库",
							icon: "ios-folder",
							permission: "menu_project_document"
						}
					},
					{
						path: "/project/:projectId/document/package",
						name: "projectDocumentPackage",
						component: () =>
							import("@/views/Project/processDoc/packageDocList.vue"),
						meta: {
							title: "打包文件列表",
							icon: "ios-folder",
							permission: "menu_project_document"
						}
					},
					{
						path: "/project/:projectId/document/fileList",
						name: "projectDocumentFileList",
						component: () => import("@/views/Project/processDoc/fileList.vue"),
						meta: {
							title: "文件列表",
							icon: "ios-folder",
							permission: "menu_project_document"
						}
					},
					{
						path: "/project/:projectId/document/genetic",
						name: "projectDocumentGenetic",
						component: () => import("@/views/Project/processDoc/Genetic.vue"),
						meta: {
							title: "遗传办文档库",
							permission: "menu_project_document"
						}
					}
				]
			},
			// 遗传办管理
			// {
			//   path: '/project/:projectId/genetic',
			//   name: 'projectGenetic',
			//   component: () => import('@/components/Layouts/RouteView.vue'),
			//   meta: {
			//     title: '遗传办管理',
			//     icon: 'icon-a-zu1216',
			//     activeIcon: 'icon-a-zu1215',
			//     permission: 'menu_project_hgc',
			//   },
			//   children: [
			//     {
			//       path: '/project/:projectId/genetic/info',
			//       name: 'projectGeneticInfo',
			//       component: () => import('@/views/Project/Genetic/Info.vue'),
			//       meta: {
			//         title: '遗传办信息',
			//         permission: 'menu_project_hgc_view',
			//       },
			//     },
			//     {
			//       path: '/project/:projectId/genetic/source',
			//       name: 'projectGeneticSource',
			//       component: () => import('@/views/Project/Genetic/Source.vue'),
			//       meta: {
			//         title: '资源样本核查',
			//         permission: 'menu_project_hgc_resource_view',
			//       },
			//     },
			//     {
			//       path: '/project/:projectId/genetic/license',
			//       name: 'projectGeneticLicense',
			//       component: () => import('@/views/Project/Genetic/License.vue'),
			//       meta: {
			//         title: '行政许可自查',
			//         permission: 'menu_project_hgc_admin_exam_view',
			//       },
			//     },
			//   ],
			// },
			// 合同管理
			{
				path: "/project/:projectId/conManage",
				name: "projectConManage",
				component: () => import("@/views/Project/ContractManage/Index"),
				meta: {
					title: "合同管理",
					icon: "icon-hetongguanli1",
					activeIcon: "icon-hetongguanli1",
					permission: "menu_project_contract_manage"
				}
			},
			// 经费管理
			{
				path: "/project/:projectId/funds",
				name: "projectFunds",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "经费管理",
					icon: "icon-jingfeiguanli",
					activeIcon: "icon-jingfeiguanli",
					permission: "menu_project_outlay"
				},
				children: [
					{
						path: "/project/:projectId/funds/org",
						name: "projectOrgFunds",
						component: () => import("@/views/Project/Funds/OrgFund/index.vue"),
						meta: {
							title: "机构经费",
							permission: "menu_project_outlay_mechanism"
						}
					},
					{
						path: "/project/:projectId/funds/ethical",
						name: "projectEthicalFunds",
						component: () =>
							import("@/views/Project/Funds/EthicalFund/index.vue"),
						meta: {
							title: "伦理经费",
							permission: "menu_project_outlay_ethic"
						}
					}
				]
			},
			// 合同资料
			{
				path: "/project/:projectId/contract",
				name: "projectContract",
				component: () => import("@/views/Project/Contract"),
				meta: {
					title: "合同资料",
					icon: "icon-hetongguanli",
					activeIcon: "icon-hetongguanlixuanzhong",
					permission: "menu_project_contract"
				}
			},
			// 财务管理
			{
				path: "/project/:projectId/finance",
				name: "projectFinance",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "财务管理",
					icon: "icon-caiwuguanli",
					activeIcon: "icon-caiwuguanlixuanzhong",
					permission: "menu_project_finance"
				},
				children: [
					// 财务信息
					{
						path: "/project/:projectId/finance/info",
						name: "projectFinanceInfo",
						component: () => import("@/views/Project/Finance/Info/index.vue"),
						meta: {
							title: "财务信息",
							permission: "menu_project_finance_info"
						}
					},
					// 财务报表
					{
						path: "/project/:projectId/finance/report",
						name: "projectFinanceFinancialStatement",
						component: () =>
							import("@/views/Project/Finance/report/FinancialStatement"),
						meta: {
							title: "财务报表",
							permission: "menu_project_finance_report"
						}
					}
				]
			},
			// 物品管理
			{
				path: "/project/:projectId/goods",
				name: "projectGoods",
				component: () => import("@/components/Layouts/RouteView.vue"),
				meta: {
					title: "物品管理",
					icon: "icon-wupinguanli",
					activeIcon: "icon-wupinguanlixuanzhong",
					permission: "menu_project_goods"
				},
				children: [
					{
						path: "/project/:projectId/goods/drug",
						name: "projectGoodsDrug",
						component: () => import("@/components/Layouts/RouteView.vue"),
						meta: {
							title: "药物管理",
							permission: "menu_project_goods_drug",
							mode: "single"
						},
						redirect: "/project/:projectId/goods/drug",
						children: [
							{
								path: "/project/:projectId/goods/drug",
								name: "projectGoodsDrugList",
								component: () => import("@/views/Project/Goods/Drug/index.vue"),
								meta: {
									title: "药物管理",
									permission: "menu_project_goods_drug"
								}
							},
							{
								path: "/project/:projectId/goods/drug/drugInventory",
								name: "projectGoodsDrugInventoryMedicine",
								component: () =>
									import(
										"@/views/Project/Goods/Drug/Inventory/Medicine/Index.vue"
									),
								meta: {
									title: "药物库存",
									permission: "menu_project_goods_drug"
								}
							},
							{
								path: "/project/:projectId/goods/drug/meterialInventory",
								name: "projectGoodsDrugInventoryMeterial",
								component: () =>
									import(
										"@/views/Project/Goods/Drug/Inventory/Meterial/Index.vue"
									),
								meta: {
									title: "耗材库存",
									permission: "menu_project_goods_drug"
								}
							}
						]
					},
					{
						path: "/project/:projectId/goods/apparatus",
						name: "projectGoodsApparatus",
						component: () =>
							import("@/views/Project/Goods/Apparatus/index.vue"),
						meta: {
							title: "器械管理",
							permission: "menu_project_goods_apparatus"
						}
					}
				]
			},
			// 会议纪要
			{
				path: "/project/:projectId/ConferenceTheme",
				name: "ConferenceTheme",
				component: () =>
					import("@/views/Project/ConferenceTheme/ConferenceTheme.vue"),
				meta: {
					title: "会议纪要",
					icon: "icon-huiyijiyao",
					activeIcon: "icon-huiyijiyao-h",
					permission: "menu_project_meeting_view"
				}
			},
			,
			// 新增研究成果
			{
				path: "/project/:projectId/achievefill",
				name: "projectAchieveFill",
				component: () =>
					import("@/views/Project/AchievementFormFillIn/FillForm"),
				meta: {
					title: "新增研究成果",
					icon: "icon-yanjiuchengguotianxie",
					activeIcon: "icon-yanjiuchengguotianxie",
					permission: "menu_project_research_write"
				}
			},
			// 项目操作记录
			{
				path: "/project/:projectId/log",
				name: "projectLog",
				component: () => import("@/views/Log/Index"),
				meta: {
					title: "项目操作记录",
					icon: "icon-xiangmucaozuojilu",
					activeIcon: "icon-xiangmucaozuojiluxuanzhong",
					permission: "menu_project_log"
				}
			}
		]
	}
]
