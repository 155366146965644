<template>
	<section class="ctms-calendar">
		<!--操作按钮-->
		<div class="ctms-calendar-header">
			<ul class="ctms-calendar-select">
				<li
					class="ctms-calendar-select__arrow ctms-calendar-select__arrow_left"
					@click="onSwitchPre()"
				>
					❮
				</li>
				<li>
					<span>{{ current.year() }}-</span
					><span>{{ current.format("MM") }}</span>
				</li>
				<li
					class="ctms-calendar-select__arrow ctms-calendar-select__arrow_right"
					@click="onSwitchNext()"
				>
					❯
				</li>
			</ul>
		</div>
		<!-- 星期 -->
		<ul class="ctms-calendar-weekday clearfix">
			<li
				v-for="(weekday, index) in [
					'周天',
					'周一',
					'周二',
					'周三',
					'周四',
					'周五',
					'周六'
				]"
				:class="[
					{
						'ctms-calendar-weekday__weekend':
							weekday === '周六' || weekday === '周天'
					},
					'ctms-calendar-weekday__column_header'
				]"
				v-bind:key="index"
			>
				{{ weekday }}
			</li>
		</ul>
		<!-- 日期 -->
		<ul class="ctms-calendar-body clearfix">
			<li
				v-for="(dayObj, index) in days"
				v-bind:key="index"
				@click="onClick(dayObj)"
				class="ctms-calendar-cell"
			>
				<div
					:style="cellHeight"
					:class="[
						{ 'ctms-calendar-cell__outer': dayObj.month() != current.month() },
						'ctms-calendar-cell_day'
					]"
				>
					<div class="ctms-calendar-cell__date">{{ dayObj.date() }}</div>
					<div class="ctms-calendar-cell__events">
						<slot name="dateCellRender" v-bind="dayObj"></slot>
					</div>
				</div>
			</li>
		</ul>
	</section>
</template>
<script>
import moment from "moment"

/**
 *  onSelect: 日期点击事件 -> moment
 *  onDateChange： 年月切换事件  -> moment
 *  dateStr: 初始设置日历到指定日期
 *  height:  日历高度，传入高度参数则日历表格高度自适应，否则不做设置，
 *  <CtmsCalendar :dateStr="201x-xx-xx"
 *                :height="500"
 *                @onSelect="fn"
 *                @onDateChange="fn"></CtmsCalendar>
 */
export default {
	props: ["dateStr", "height"],
	name: "CtmsCalendar",
	data() {
		return {
			cellHeight: {
				// 表格高度
				height: "auto",
				lineHeight: 1
			},
			year: null, // 当前年份
			month: null, // 当前月份
			today: moment().format("YYYY-MM-DD"), // 今天日期状态字符串
			current: this.dateStr ? moment(this.dateStr) : moment(), // 当前时间
			days: []
		}
	},
	created() {
		this.cellHeight = {
			height: `${(this.height - 125) / 6}px`
		}
		this.initDate(this.current)
	},
	methods: {
		/**
		 * 通过日期，填充日期表格（7 * 7）
		 * @param cur moment 格式时间
		 */
		initDate(cur) {
			const dt = cur ? moment(cur) : moment()

			// 设置状态
			this.current = dt
			;[this.year, this.month, this.days.length] = [
				dt.year(),
				dt.month() + 1,
				0
			]

			// moment().weekday()返回星期坐标 -> (0-6): 星期一:0, 星期二:1， ....，星期天:6。
			// 计算日历第一格起始时间：当月1号  - （当月1号的星期坐标，再往前推一天）。
			// 比如当前月1号是星期二: 起始日期 = (当前月1号) - ([1 + 1]天), 也就是起始时间是上个星期天.
			// 从起始时间开始循环增加天数填充日期，直至填满42格
			const startOfMonth = dt.startOf("month")
			const startDate = moment(startOfMonth).add(
				-(startOfMonth.weekday() + 2),
				"days"
			)
			for (let i = 0; i < 42; i += 1) {
				this.days.push(moment(startDate.add(1, "days")))
			}
		},
		/**
		 * 日期点击事件，$emit返回被点击的moment格式时间
		 * 非当前月的日期被点击，将切换日期
		 * @param momentDate 点击的时间
		 */
		onClick(momentDate) {
			/* if (this.current.format('YYYY-MM') !== momentDate.format('YYYY-MM')) {
        this.initDate(momentDate);
      } */
			this.$emit("onSelect", momentDate)
		},
		onSwitchPre() {
			this.onSwitch(-1)
		},
		onSwitchNext() {
			this.onSwitch(1)
		},
		onSwitch(monthNum) {
			const newDate = this.current.startOf("month").add(monthNum, "months")
			this.initDate(newDate)
			this.$emit("onDateChange", newDate)
		}
	}
}
</script>
<style lang="less" scoped>
@import "../../../my-theme/color.less";
.ctms-calendar {
	width: 100%;
	margin: 0 auto;

	.ctms-calendar-header {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 23px 0;
		font-size: 16px;
	}

	.ctms-calendar-select {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		padding: 0 20px;
		color: #202730;
	}

	.ctms-calendar-select__arrow {
		padding: 0 65px;
		color: #404447;
		cursor: pointer;
		&:hover {
			color: @primary-color;
		}
	}

	.ctms-calendar-select__arrow_left {
	}

	.ctms-calendar-select__arrow_right {
	}

	.ctms-calendar-weekday {
		margin: 0;
		color: #8d99a3;
		font-weight: 600;
		font-size: 16px;
		list-style: none;
		height: 49px;
		line-height: 49px;
		padding: 0;
		.ctms-calendar-weekday__column_header {
			float: left;
			width: 14.28%;
			text-align: center;
			/*border-left: 1px solid #eee;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;*/
		}
	}

	.ctms-calendar-body {
		padding: 0;
		background: #ffffff;
		margin: 0;
		.ctms-calendar-cell {
			list-style-type: none;
			float: left;
			width: 14.28%;
			text-align: center;
			color: #202730;
			position: relative;
			.ctms-calendar-cell__date {
				text-align: right;
				line-height: 15px;
				height: 15px;
			}

			.ctms-calendar-cell__events {
				position: absolute;
				width: 100%;
				top: 20px;
				padding-right: 8px;
				padding-bottom: 4px;
			}

			.ctms-calendar-cell_day {
				padding: 4px 8px;
				border-top: 1px solid #eee;
				text-align: center;
			}

			.ctms-calendar-cell__active {
				background: #e6f7ff;
			}

			.ctms-calendar-cell__today {
				border-top: 2px solid #00b8ec;
				background: #e6f7ff;
			}

			.ctms-calendar-cell__outer {
				color: #bababa;
			}
		}
	}
}
</style>
