import _ from "lodash"
import Vue from "vue"
import iView from "view-design"
import element from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
// import './styles/element-variables.scss'
import { Calendar, LocaleProvider } from "ant-design-vue"
import "./my-theme/index.less"
import CtmsComponents from "@/components/CtmsComponents"
import vChart from "viser-vue"
import VueAwesomeSwiper from "vue-awesome-swiper"
import App from "./App.vue"
import router from "./router"
import store from "./store/index"
import rules from "./utils/rules"
import "./permission"
import "./utils/filter"
import { asyncDo } from "./utils/util"

import "@/assets/swiper.min.css"
import "./icons" // icon

// 引入ant-design-vue组件
Vue.component("ACalendar", Calendar)
// Vue.component('ALocaleProvider', LocaleProvider);
Vue.component("ALocaleProvider")
Vue.use(iView)
Vue.use(vChart)
Vue.use(CtmsComponents)
Vue.use(VueAwesomeSwiper)
Vue.use(element)

Vue.config.productionTip = false
Vue.prototype._ = _
Vue.prototype.rules = rules
Vue.prototype.$rules = rules
Vue.prototype.$asyncDo = asyncDo
Vue.prototype.$baseUrl = process.env.VUE_APP_REQUEST_BASE_URL

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app")
