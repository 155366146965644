export default {
  namespaced: true,
  state: {
    searchParams: {
      projectTypeId: "",
      createTimeStart: "",
      createTimeEnd: ""
    },
    times: []
  },
  mutations: {
    SET_SEARCH_PARAMS(state, params) {
      state.searchParams = { ...params }
    },
    SET_TIMES(state, times) {
      state.times = [...times]
    }
  },
  actions: {
    updateSearchParams({ commit }, params) {
      commit('SET_SEARCH_PARAMS', params)
    },
    updateTimes({ commit }, times) {
      commit('SET_TIMES', times)
    }
  }
} 