<template>
	<div class="ctms-action">
		<slot></slot>
	</div>
</template>

<script>
import "./style.less"

export default {
	name: "CtmsAction"
}
</script>
