import request from "@/utils/request"

// 登录
export const login = data =>
	request("/auth/login", {
		method: "post",
		body: data
	})

// 找回密码
export const submitAccount = data =>
	request("/auth/submit-account", {
		method: "post",
		body: data
	})
// 自动创建账号 免登录 儿童医院
export const apiCreateAmount = data =>
	request("/system/project/oa-to-st", {
		method: "get",
		params: data
	})

// 重置密码
export const resetPassword = data =>
	request("/auth/change-password", {
		method: "post",
		body: data
	})

// 退出登录
export const logout = () =>
	request("/auth/logout", {
		method: "post"
	})

// 获取个人信息
export const getInfo = () => request("/auth/self-info")

// 获取系统权限
export const getSystemPermission = () =>
	request("/auth/self-info/system-permissions")

// 获取项目权限
export const getProjectPermission = projectId =>
	request("/auth/self-info/project-permissions", {
		params: {
			projectId
		}
	})
// 查询审批结果
export const getAuditResult = data =>
	request("/auth/register", {
		params: data
	})

// 获取登录验证码
export const getCode = () => request("/auth/validate-code")

// 获取logo
export const getHospitalLogo = () => request("/auth/orgs/hosp-logo")

// 获取 ip地址 onlyoffice在线批注docker
export const getIp = () => request("/auth/get-onlyOffice")

// 修改密码
export const changePassword = data =>
	request("/auth/self-info/reset-pwd", {
		method: "put",
		body: data
	})
