import { apiCreateAmount, getInfo, login, logout } from "@/api/user/user"
import { clearStorage, setStorage } from "@/utils/storage"
import { Message } from "view-design"
import publicApi from "@/api/public"
import router from "@/router"
import { getParameterByName } from "@/utils/util"

const { publicGetDictionaryList } = publicApi

const user = {
	namespaced: true,
	state: {
		token: "",
		name: "",
		avatar: "",
		info: {},
		projectStepInfo: [],
		projectAllStepInfo: [],
		processList: []
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		},
		clearSession: state => {
			Object.assign(state, {
				token: "",
				name: "",
				avatar: "",
				info: {}
			})
			clearStorage()
			const client = window.location.href.split("?")[0].split("#")[1]
			if (client && client.indexOf("mobile") !== -1) {
				router.push({
					name: "mobileLogin",
					query: {
						id: getParameterByName("id"),
						time: getParameterByName("time")
					}
				})
			} else {
				router.push({ name: "login" })
			}
		}
	},
	actions: {
		// 登录
		loginDo({ commit, dispatch }, token) {
			commit("updateState", {
				token
			})
			setStorage({ token })
			setStorage({ isFirstShow: "1" }) // 用来控制更换密码提醒，只在登录后提示一次。
			// 获取项目阶段
			dispatch("getProjectStepInfo")
			// 获取流程信息
			dispatch("getProcessList")
			// 获取个人信息
			dispatch("getInfo").then(() => {
				Message.success("登录成功!")
				router.push("/")
			})
		},
		// 登录
		async login({ commit, dispatch }, userInfo) {
			const res = await login(userInfo)
			if (res) {
				dispatch("loginDo", res.data)
				return true
			}
			return false
		},
		// 登录
		async shrtyyLogin({ commit, dispatch }, userInfo) {
			const res = await apiCreateAmount(userInfo)
			if (res) {
				dispatch("loginDo", res.data)
				return true
			}
			router.push("/login")
			return false
		},
		// 获取项目阶段
		async getProjectStepInfo({ commit }) {
			const res = await publicGetDictionaryList({ type: 25 })
			if (res) {
				const arr = res.data && res.data.length ? res.data[0].leaf : []
				const info = arr.map(item => ({
					...item,
					obj: {
						...item.obj,
						nickname: Number(item.obj.nickname)
					}
				}))
				commit("updateState", {
					projectAllStepInfo: info, // 包含禁用
					projectStepInfo: info.filter(o => o.obj.isDel === 0)
				})
			}
		},
		// 获取流程状态
		async getProcessList({ commit }) {
			const res = await publicGetDictionaryList({ type: 24, isDel: 0 })
			if (res) {
				commit("updateState", {
					processList: res.data && res.data.length ? res.data[0].leaf : []
				})
			}
		},
		// 获取用户信息
		async getInfo({ state, commit }) {
			const res = await getInfo()
			if (res) {
				const { realName, photo } = res.data
				commit("updateState", {
					name: realName,
					avatar: photo
						? `${process.env.VUE_APP_REQUEST_BASE_URL}/document/attachment/photo/${photo}?token=${state.token}`
						: "",
					info: res.data
				})
				setStorage({
					name: realName,
					avatar: photo
						? `${process.env.VUE_APP_REQUEST_BASE_URL}/document/attachment/photo/${photo}?token=${state.token}`
						: "",
					info: res.data
				})
			}
		},

		// 登出
		async logout({ commit }) {
			const res = await logout()
			if (res) {
				Message.success("退出登录成功!")
				commit("permission/clearMenuPermissions", {}, { root: true })
				commit("clearSession")
				localStorage.clear()
			}
		}
	}
}

export default user
