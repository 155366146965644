<template>
	<div>
		<Upload
			v-bind="uploadProps"
			:before-upload="beforeUpload"
			:show-upload-list="isShow"
			:on-success="onSuccess"
			:on-format-error="onFormatError"
			:data="extraData"
			:on-remove="onRemove"
			:on-preview="handlePreview"
			:accept="accept"
			:format="format"
			:on-error="onError"
			ref="upload"
		>
			<Button :disabled="uploadProps.disabled">上传</Button>
		</Upload>
		<div v-if="upType === 'image'" class="uplist">
			<div
				class="demo-upload-list"
				v-for="(item, index) in newUploadList"
				:key="index"
			>
				<img :src="item.url" />
				<div class="demo-upload-list-cover">
					<Icon type="ios-eye-outline" @click.native="handleView(item)"></Icon>
					<Icon
						type="ios-trash-outline"
						@click.native="handleRemove(item, index)"
					></Icon>
				</div>
			</div>
		</div>

		<Modal title="查看图片" v-model="viewImage" width="700">
			<div class="viewImg">
				<img :src="viewImg.url" v-if="viewImage" />
			</div>
			<div slot="footer">
				<Button style="margin-right: 8px" @click="viewImage = false"
					>关闭</Button
				>
			</div>
		</Modal>
	</div>
</template>
<script>
import { formDataToMD5 } from "@/utils/util"

export default {
	props: {
		extraData: {
			type: Object,
			default() {
				return {
					md5: ""
				}
			}
		},
		uploadList: {
			type: Array
		},
		upType: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			viewImage: false,
			viewImg: {}
		}
	},
	computed: {
		uploadProps() {
			const uploadProps = this._.defaultsDeep({}, this.$attrs)
			return uploadProps
		},
		accept() {
			if (this.upType === "image") {
				return "image/jpe,image/jpg,image/jpeg,image/png,image/gif,image/tiff"
			}
			if (this.upType === "attach") {
				return "application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,"
			}
			return this.uploadProps.accept
		},
		format() {
			if (this.upType === "image") {
				return ["jpe", "jpg", "jpeg", "png", "gif", "tiff"]
			}
			if (this.upType === "attach") {
				return ["pdf", "xlsx", "xls"]
			}
			return this.uploadProps.format
		},
		isShow() {
			if (this.upType === "image" || this.upType === "file") {
				return false
			}
			return true
		},
		newUploadList() {
			const f = this.uploadList.map(item => {
				Object.assign(item, {
					url: `${this.$baseUrl}/document/attachment/photo/${item.id}?token=${this.$store.state.user.token}`
				})
				return item
			})
			return f
		}
	},
	methods: {
		beforeUpload(file) {
			formDataToMD5(file, md5Str => {
				this.extraData.md5 = md5Str
				// 获取md5后进行文件上传
				this.$refs.upload.post(file)
			})
			return false
		},
		clearList() {
			this.$refs.upload.clearFiles()
		},
		onSuccess(res, file, fileData) {
			if (res.code !== 0) {
				this.$Message.error(res.message)
			} else {
				this.$set(fileData[fileData.length - 1], "id", res.data)
				this.$Message.success("上传成功!")
				this.$emit("updateList", fileData)
			}
		},
		// 格式错误
		onFormatError() {
			this.$Message.error("上传文件格式不正确！")
		},
		// 上传错误
		onError() {
			this.$Message.error("文件上传失败！")
		},
		onRemove(file) {
			// 删除附件
			this.$emit("removeFile", file)
		},
		// 预览
		handlePreview(file) {
			window.open(
				`${process.env.VUE_APP_REQUEST_BASE_URL}/document/attachment/inline/${file.id}?token=${this.$store.state.user.token}`
			)
		},
		handleView(obj) {
			this.viewImg = obj
			this.viewImage = true
		},
		handleRemove(obj) {
			this.$emit("removeFile", obj)
		}
	}
}
</script>
<style lang="less" scoped>
.uplist {
	margin-top: 15px;
}
.viewImg {
	max-height: 550px;
	text-align: center;
	overflow: auto;
}
.demo-upload-list {
	display: inline-block;
	width: 60px;
	height: 60px;
	text-align: center;
	line-height: 60px;
	border: 1px solid transparent;
	border-radius: 4px;
	overflow: hidden;
	background: #fff;
	position: relative;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
	margin-right: 4px;
}
.demo-upload-list img {
	width: 100%;
	height: 100%;
}
.demo-upload-list-cover {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
}
.demo-upload-list:hover .demo-upload-list-cover {
	display: block;
}
.demo-upload-list-cover i {
	color: #fff;
	font-size: 20px;
	cursor: pointer;
	margin: 0 2px;
}
</style>
