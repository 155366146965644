import { projectRouterMap, systemRouterMap } from "@/config/router.config"
import { getProjectPermission, getSystemPermission } from "@/api/user/user"

// 过滤路由权限
function filterRouter(routerMap, permissionList = []) {
	const routerArr = []
	routerMap.forEach(route => {
		if (permissionList.indexOf(route.meta.permission) > -1) {
			routerArr.push({
				...route
			})
			const len = routerArr.length
			if (route.children && route.children.length) {
				const children = filterRouter(route.children, permissionList)
				if (children.length) {
					routerArr[len - 1].children = children
				}
			}
		}
	})
	return routerArr
}

const permission = {
	namespaced: true,
	state: {
		systemMenuPermissions: undefined,
		projectMenuPermissions: undefined,
		systemActionPermissions: [],
		projectActionPermissions: [],
		systemRouter: [],
		projectRouter: []
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		},
		clearProjectMenuPermissions: state => {
			Object.assign(state, {
				projectMenuPermissions: undefined,
				projectActionPermissions: []
			})
		},
		clearMenuPermissions: state => {
			Object.assign(state, {
				projectMenuPermissions: undefined,
				systemMenuPermissions: undefined,
				systemActionPermissions: [],
				projectActionPermissions: []
			})
		}
	},
	actions: {
		// 获取用户系统级别权限
		async getSystemPermission({ rootState, commit }) {
			const res = await getSystemPermission()
			if (res) {
				const systemMenuPermissions = res.data.filter(
					item => item.indexOf("menu_") > -1 && item.indexOf("_project_") === -1
				)
				// 其他权限（不是通过后端配置权限来获取的权限）
				const otherMenuPermission = []
				/*
				 * menu_setting_organization_set
				 * 维护组织信息的权限与正常菜单权限不同
				 * 是根据self-info中的isOrgAdmin字段来确定的
				 * */
				if (rootState.user.info.isOrgAdmin) {
					otherMenuPermission.push("menu_setting_organization_set")
				}
				const systemRouter = filterRouter(systemRouterMap[0].children, [
					...res.data,
					...otherMenuPermission,
					""
				])
				const systemActionPermissions = res.data.filter(
					item => item.indexOf("btn_") > -1
				)
				commit("updateState", {
					systemMenuPermissions: [
						...systemMenuPermissions,
						...otherMenuPermission
					],
					systemRouter,
					systemActionPermissions
				})
				return true
			}
			return false
		},
		// 获取用户项目级别权限
		async getProjectPermission({ commit }, projectId) {
			const res = await getProjectPermission(projectId)
			if (res) {
				const projectMenuPermissions = res.data.filter(
					item => item.indexOf("menu_") > -1 && item.indexOf("_project_") > -1
				)
				const projectRouter = filterRouter(projectRouterMap[0].children, [
					...res.data,
					""
				])
				const projectActionPermissions = res.data.filter(
					item => item.indexOf("btn_") > -1
				)
				commit("updateState", {
					projectMenuPermissions,
					projectRouter,
					projectActionPermissions
				})
				return true
			}
			return false
		}
	}
}

export default permission
