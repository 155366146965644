import Vue from "vue"
import Router from "vue-router"
import {
	baseRouterMap,
	systemRouterMap,
	projectRouterMap
} from "@/config/router.config"

Vue.use(Router)

const router = new Router({
	// mode: 'history',
	routes: [...baseRouterMap, ...systemRouterMap, ...projectRouterMap]
})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}

export default router
