import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user"
import permission from "./modules/permission"
import project from "./modules/project"
import config from "./modules/config"
import process from "./modules/process"
import enumerateTypes from "@/config/enumerate.config"
import publicApi from "@/api/public"
import { setStorage } from "@/utils/storage"
import report from './modules/report'

const { publicGetEnumerate, publicGetEnv } = publicApi

Vue.use(Vuex)
export default new Vuex.Store({
	state: {
		contentHeight: document.documentElement.clientHeight - 136,
		enumerate: {},
		org: {},
		environment: "xinhua" || sessionStorage.getItem("environment")
	},
	mutations: {
		updateState: (state, payload) => {
			Object.assign(state, payload)
		}
	},
	actions: {
		// 获取所有的枚举字段
		async getEnumerate({ commit }) {
			const res = await publicGetEnumerate({ keys: enumerateTypes })
			if (res) {
				commit("updateState", {
					enumerate: res.data
				})
				return true
			}
			return false
		},
		// 获取当前登录环境
		async getEnv({ commit }) {
			const res = await publicGetEnv()
			if (res) {
				commit("updateState", {
					environment: res.data
				})
				setStorage({ environment: res.data })
				return true
			}
			return false
		}
	},
	modules: {
		user,
		permission,
		project,
		config,
		process,
		report
	}
})
