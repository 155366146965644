import request from "@/utils/request"

const moduleName = "/flowable"

export default {
	// 获取所有流程
	apiAllProcess: data =>
		makeApiCall(moduleName, "processes-in-project", "get", { params: data }),

	// 删除已启动流程
	apiDelProcess: data =>
		makeApiCall(
			moduleName,
			`project/${data.projectId}/process/${data.procInstId}`,
			"delete",
			{ params: data }
		),

	// 启动流程
	apiStartProcess: data =>
		makeApiCall(moduleName, "start-process", "post", { body: data }),

	// 获取已启动流程列表
	apiGetProcessList: data =>
		makeApiCall(moduleName, "processes", "get", { params: data }),

	// 获取已启动流程图渲染数据
	apiGetProcessView: data =>
		makeApiCall(moduleName, `process/${data.id}/flow`, "get", {}),

	// 获取审批历史
	apiGetProcessHistory: data =>
		makeApiCall(moduleName, `process/${data.id}/todo-his`, "get", {}),

	// 获取所有审批历史，包含子流程的
	apiGetAllProcessHistory: data =>
		makeApiCall(moduleName, `process/${data.id}/audit-opinion-list`, "get", {}),

	// 获取历史表单详情
	apiGetHistoryForm: data =>
		makeApiCall(moduleName, `todo/${data.id}/his-data`, "get", {}),

	// 流程重新部署
	apiModelRedeploy: data =>
		makeApiCall(moduleName, "process/model-redeploy", "put", { body: data }),

	// 获取正在进行中的流程实例
	apiGetProcessing: data =>
		makeApiCall(moduleName, "processes-ing-in-project", "get", {
			params: data
		}),
	apiGetProjectModels: data =>
		makeApiCall(moduleName, `project/${data.projectId}/models`, "get", {
			params: data
		}),
	// 获取各流程管理数据
	apiGetModelList: data =>
		makeApiCall(moduleName, `model-list`, "get", {
			params: data
		})
}

// 辅助函数
function validateParams(data) {
	if (!data || typeof data !== "object") {
		throw new Error("Invalid parameters")
	}
}

const requestWithErrorHandling = (url, options) => {
	try {
		return request(url, options)
	} catch (error) {
		console.error("Request failed:", error)
		throw error
	}
}

function buildUrl(moduleName, path, params) {
	let url = `${moduleName}/${path}`
	if (params) {
		const query = new URLSearchParams(params).toString()
		url += `?${query}`
	}
	return url
}

const makeApiCall = (moduleName, path, method, data) => {
	validateParams(data)
	const url = buildUrl(moduleName, path, data.params || {})
	const options = {
		method,
		...(method === "post" || method === "put" ? { body: data.body } : {})
	}
	return requestWithErrorHandling(url, options)
}
