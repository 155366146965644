<template>
	<div class="tiny-editor-wrap">
		<div
			:id="id"
			:style="{
				height: this.height - 40 + 'px',
				overflowY: 'scroll',
				overflowX: 'auto'
			}"
		></div>
	</div>
</template>
<script>
import tinymce from "tinymce/tinymce"
import "tinymce/themes/modern/theme"
import "tinymce/plugins/advlist"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/link"
import "tinymce/plugins/image"
import "tinymce/plugins/charmap"
import "tinymce/plugins/print"
import "tinymce/plugins/preview"
import "tinymce/plugins/anchor"
import "tinymce/plugins/textcolor"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/media"
import "tinymce/plugins/table"
import "tinymce/plugins/contextmenu"
import "tinymce/plugins/paste"
import "tinymce/plugins/code"
import "tinymce/plugins/help"
import "tinymce/plugins/wordcount"

export default {
	name: "CtmsTinyEditor",
	props: {
		value: {
			type: String,
			required: true
		},
		setting: {},
		height: {
			type: Number,
			default: 500
		}
	},
	watch: {
		value(val) {
			tinymce.activeEditor.setContent(val)
		}
	},
	data() {
		return {
			id: `editor-${new Date().getTime()}`,
			status: "init",
			ctmsEditor: null
		}
	},
	methods: {
		// 在光标处添加内容
		insertContent(insertHtml) {
			this.ctmsEditor.insertContent(insertHtml)
		},
		// 获取内容
		getContent() {
			// 由于api中的getContent方法返回的是空的，所以用这个方式做个转换
			return this.ctmsEditor.getDoc().body.innerHTML
		},
		// 设置内容
		setContent(conTxt) {
			this.ctmsEditor.setContent(conTxt)
		}
	},
	mounted() {
		const setting = {
			selector: `div#${this.id}`,
			language: "zh_CN",
			height: this.height,
			language_url: `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/javascript/tinymce/zh_CN.js`,
			skin_url: `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/javascript/tinymce/skins/lightgray`,
			plugins: [
				"advlist autolink lists print textcolor",
				"searchreplace visualblocks",
				"table contextmenu paste"
			],
			menubar: false,
			toolbar:
				"fontsizeselect | " +
				"bold italic forecolor backcolor | " +
				"alignleft aligncenter alignright | " +
				"bullist numlist | " +
				"table tabledelete tablemergecells tablesplitcells | " +
				"undo redo",
			branding: false,
			resize: false,
			elementpath: false,
			statusbar: false,
			paste_retain_style_properties: "all",
			content_style:
				"body {overflow-x: hidden;padding-right: 3px; min-height: 30px;} table {width: 100% !important; }",
			// 在富文本编辑器初始化成功之后，抛出onReady事件
			init_instance_callback: editor => {
				this.ctmsEditor = editor
				tinymce.activeEditor.setContent(this.value)
				this.$emit("onReady")
			}
		}
		Object.assign(setting, this.setting)
		tinymce.init(setting)
	},
	beforeDestroy() {
		tinymce.get(this.id).destroy()
	}
}
</script>
