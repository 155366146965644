import NProgress from "nprogress" // progress bar
import Router from "vue-router"
import { baseRouterMap } from "@/config/router.config"
import router from "./router"
import store from "./store"
import { getStorage } from "./utils/storage"
import "nprogress/nprogress.css" // progress bar style

// NProgress 配置
NProgress.configure({ showSpinner: true })

// 白名单
const whiteList = baseRouterMap.map(item => item.name)

// 判断是否有token
const checkIsHaveToken = () => {
	// 首先，判断store中有没有token
	if (store.state.user.token) {
		return true
		// 其次，如果storage里面有token，就获取storage中的token，然后赋值给store
	}
	if (getStorage("token")) {
		store.commit("user/updateState", {
			token: getStorage("token"),
			name: getStorage("name"),
			avatar: getStorage("avatar"),
			info: getStorage("info")
		})
		return true
	}
	return false
}

// 判断是否有权限列表
const checkIsHavePermissionList = type => {
	const { projectMenuPermissions, systemMenuPermissions } =
		store.state.permission

	return type === "Project" ? !!projectMenuPermissions : !!systemMenuPermissions
}

// 判断是否有权限
const checkIsHavePermission = (type, to) => {
	const { projectMenuPermissions, systemMenuPermissions } =
		store.state.permission
	const {
		meta: { permission },
		fullPath
	} = to

	// 如果没有任何系统权限，则跳转到一个空的首页
	if (fullPath === "/") {
		if (!systemMenuPermissions.length) {
			return true
		}
	}

	// 根据权限列表返回权限
	return type === "Project"
		? projectMenuPermissions.indexOf(permission) > -1
		: systemMenuPermissions.indexOf(permission) > -1
}

// 获取根目录重定向路径
const getRootRedirectPath = to => {
	const { systemRouter, projectRouter } = store.state.permission

	const { fullPath } = to

	// 系统路由根目录
	if (fullPath === "/") {
		return systemRouter.length ? systemRouter[0].path : false
	}
	// 项目路由根目录
	if (
		/^\/project\/[\d]+\/workbench$/.test(fullPath) ||
		/^\/project\/[\d]+$/.test(fullPath)
	) {
		if (!projectRouter.length) {
			return false
		}
		const {
			params: { projectId },
			name
		} = to
		const { path } = projectRouter[0]
		if (name !== projectRouter[0].name) {
			return path.replace(":projectId", projectId)
		}
	}
	return false
}

// 获取普通重定向地址
const getRedirectPath = (to, type) => {
	const { systemRouter, projectRouter } = store.state.permission

	const { name } = to

	// 项目路由

	if (type === "Project") {
		const {
			params: { projectId }
		} = to
		// 根据当前route的name找到对应的route
		const route = projectRouter.find(item => item.name === name)
		// 如果有子路由
		if (route && route.children && route.children.length) {
			const newPath = route.children[0].path
			// 返回替换过projectId的子路由
			return newPath.replace(":projectId", projectId)
		}
		// 如果根据当前名称没找到，则认为是底层路由，不需要重定向
		return false
	}

	// 系统路由

	// 根据当前route的name找到对应的route
	const route = systemRouter.find(item => item.name === name)
	// 如果有子路由
	if (route && route.children && route.children.length) {
		// 返回子路由
		return route.children[0].path
	}
	// 如果根据当前名称没找到，则认为是底层路由，不需要重定向
	return false
}

// 重置路由，避免重复警告
router.$addRoutes = params => {
	router.matcher = new Router().matcher
	router.addRoutes(params)
}

// router进入前的判断
router.beforeEach((to, from, next) => {
	const allRoutes = [...router.options.routes]
	// 如果是环境单独加载系统路由
	// if (sessionStorage.getItem('environment') === 'xinhua') {
	//   allRoutes[allRoutes.length - 2].children = allSystemRouter.systemRouterList;
	//   router.$addRoutes([...allRoutes]);
	// }
	const { projectMenuPermissions } = store.state.permission

	// 使用nprogress进度条
	NProgress.start()

	// 判断是项目路由还是系统路由
	const type = to.path.indexOf("/project/") > -1 ? "Project" : "System"

	// 如果是系统路由,清理项目路由
	if (type === "System" && projectMenuPermissions) {
		store.commit("permission/clearProjectMenuPermissions")
	}

	// 判断路由是否正确（是否有name)如果路由错误，则跳转到404
	if (!to.name) {
		next({ name: "Exception-404", replace: true })
		NProgress.done()
		return false
	}

	// 判断是否有token
	const isHaveToken = checkIsHaveToken()

	// 首先判断是否是白名单,如果是白名单,则不判断权限
	if (whiteList.indexOf(to.name) > -1) {
		// 如果目标是登录页，则跳转到首页
		if (to.name === "login" && isHaveToken) {
			next("/")
			NProgress.done()
			return false
		}
		next()
		return false
	}

	// 如果有token
	if (isHaveToken) {
		// 判断是否有权限列表
		const isHavePermissionList = checkIsHavePermissionList(type)
		// 如果没有权限列表，先请求权限列表
		if (!isHavePermissionList) {
			// 判断是否有系统权限
			if (type === "Project" && !store.state.permission.systemMenuPermissions) {
				store.dispatch("permission/getSystemPermission")
			}
			store
				.dispatch(`permission/get${type}Permission`, to.params.projectId)
				.then(res => {
					if (res) {
						// 请求成功之后，再重新进入当前页面
						next({ ...to, replace: true })
						return false
					}
					// 请求失败，跳转到首页
					next({ name: "/", replace: true })
					NProgress.done()
					return false
				})
			return false
		}

		// 首先处理根目录重定向跳转的路径
		const rootRedirectPath = getRootRedirectPath(to)
		if (rootRedirectPath) {
			next(rootRedirectPath)
			NProgress.done()
			return false
		}

		// 再处理普通目录重定向跳转的路径
		const redirectPath = getRedirectPath(to, type)
		if (redirectPath) {
			next(redirectPath)
			NProgress.done()
			return false
		}

		// 判断是否有路由权限
		const isHavePermission = checkIsHavePermission(type, to)

		// 判断没有路由权限，则跳转到403
		if (!isHavePermission) {
			next({ name: "Exception-403", replace: true })
			NProgress.done()
			return false
		}

		// 正常情况
		next()
		return false
	}

	// 如果没有token则跳转到登录
	next("/login")
	NProgress.done()
	return false
})

// router进入后的动作
router.afterEach(() => {
	NProgress.done()
})
