import request from "@/utils/request"

const moduleName = "/common/settings/on-off"

// 封装通用请求方法
function makeRequest(url, options) {
	return request(url, options).catch(error => {
		console.error("请求失败:", error)
		throw error
	})
}

export default {
	apiGetList: () =>
		makeRequest(moduleName, {
			method: "get"
		}),
	apiSetList: data =>
		makeRequest(moduleName, {
			method: "put",
			body: data
		}),
	apiGetSetting: data =>
		makeRequest("/common/setting", {
			method: "get",
			params: data
		}),
	apiSetProcessSetting: (data, id) =>
		makeRequest(`/common/setting/${id}`, {
			method: "put",
			body: data
		}),
	// 获取系统配置信息
	apiGetEmailSettings: id =>
		makeRequest(`/common/setting/${id}`, {
			method: "get"
		}),
	// 更新系统配置信息
	apiSetEmailSettings: (data, id) =>
		makeRequest(`/common/setting/${id}`, {
			method: "put",
			body: data
		}),
	apiGetMenu: () =>
		makeRequest("/common/permission/all-nodes?type=1", {
			method: "get"
		}),
	apiSetMenu: data =>
		makeRequest("/common/permission/all-nodes", {
			method: "post",
			body: data
		}),
	// 上传 申办方手册
	apiUpload: (data, params) =>
		makeRequest("/common/upload-file", {
			method: "post",
			body: data,
			params
		}),
	apiSetDefaultTeam: data =>
		makeRequest("/projects/default-team", {
			method: "put",
			body: data
		}),
	apiGetPrivary: () =>
		makeRequest("/common/get-user-privacy-agreement", {
			method: "get"
		}),
	// 上传用户协议或者隐私政策
	apiUploadPrivary: (data, id) =>
		makeRequest(`/common/upload-agreement/${id}`, {
			method: "post",
			body: data
		}),
	// 删除用户协议或者隐私政策，恢复默认预置协议
	apiDelPrivary: id =>
		makeRequest(`/common/delete-agreement/${id}`, {
			method: "delete"
		})
}
