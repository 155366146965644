import request from "@/utils/request"
import commonApi from "@/api/api"

const moduleName = "/project"

// 动态生成 API 路径
const apiPaths = {
	getResWorker: "/projects/default-team",
	getSponsorWorker: "/projects/sponsor-worker",
	getProjectTodo: id => `/flowable/project/${id}/todo`,
	getProjectProcess: projectId => `/project/${projectId}/running-processes`,
	getProjectProcessNum: "/flowable/proc-inst-num",
	getProjectList: "/projects",
	getProjectRoleCol: roleId => `/auth/role/${roleId}`,
	getDraftsList: "/draft-projects",
	getDraftsDetail: projectId => `/draft-project/${projectId}`,
	addDrafts: "/draft-project",
	updateDrafts: id => `/project-draft/${id}`,
	addBizDrafts: "/project-biz/draft-project",
	updateBizDrafts: id => `/project-biz/project-draft/${id}`,
	delDrafts: projectId => `/project/${projectId}/remove`,
	getProjectDetail: projectId => `/project/${projectId}`,
	getShowAmount: "/projects/contract",
	apiGetDepart: "/auth/dept/org",
	apiGetSetting: "/common/setting",
	apiGetProjTypeList: "/project/project-configs",
	apiGetProjFormList: "/project/project-configs-page",
	apiAddFormType: "/project/project-config",
	apiEditFormType: projectConfigId =>
		`/project/project-config/${projectConfigId}`,
	apiDelFormType: projectConfigId =>
		`/project/project-config/${projectConfigId}`,
	apiCopyFormSetting: projectConfigId =>
		`/project/project-config/${projectConfigId}/copy`,
	apiGetFormSetting: projectConfigId =>
		`/project/project-config/${projectConfigId}`,
	apiSetFormSetting: projectConfigId =>
		`/project/project-config/${projectConfigId}/save-components`,
	apiSetFormSettingConfig: projectConfigId =>
		`/project/project-config/${projectConfigId}/submit`,
	apiGetSearchList: "/project-genetic/query-params",
	apiGetProjectStepInfo: "/project/project-step-info",
	apiGetProcessStepInfo: "/flowable/model-category-info",
	apiTerminateProject: projectId => `/project/${projectId}/project-terminate`,
	apiGetRepeatPrjName: "/project/repeat-project-name",
	apiGetFollowCheck: projectId => `/project/${projectId}/notify`,
	apiSetFollowCheck: "/project/notify",
  apiResearchWardInfo: projectId => `/research_ward_info/${projectId}`,
	apiGetCode: projectId => `/project/exist-mechanism/${projectId}`,
	apiUpdateCode: "/project/modify-mechanism"
}

// 抽象通用请求方法
const makeRequest = (path, method, data) => {
	try {
		const options = { method }
		if (method === "get") {
			options.params = data
		} else {
			options.body = data
		}
		return request(path, options)
	} catch (error) {
		console.error("请求失败:", error)
		throw error
	}
}

export default {
	...commonApi(moduleName),
	getResWorker: data => makeRequest(apiPaths.getResWorker, "get", data),
	getSponsorWorker: data => makeRequest(apiPaths.getSponsorWorker, "get", data),
	getResearchWardInfo: data => makeRequest(apiPaths.apiResearchWardInfo(data.projectId), "get"),
	postResearchWardInfo: data => makeRequest(apiPaths.apiResearchWardInfo(data.projectId), "post", data),
	getProjectTodo: data =>
		makeRequest(apiPaths.getProjectTodo(data.id), "get", data),
	getProjectProcess: (projectId, data) =>
		makeRequest(apiPaths.getProjectProcess(projectId), "get", data),
	getProjectProcessNum: data =>
		makeRequest(apiPaths.getProjectProcessNum, "get", data),
	getProjectList: data => makeRequest(apiPaths.getProjectList, "get", data),
	getProjectRoleCol: roleId =>
		makeRequest(apiPaths.getProjectRoleCol(roleId), "get"),
	getDraftsList: data => makeRequest(apiPaths.getDraftsList, "get", data),
	getDraftsDetail: projectId =>
		makeRequest(apiPaths.getDraftsDetail(projectId), "get"),
	addDrafts: data => makeRequest(apiPaths.addDrafts, "post", data),
	updateDrafts: data =>
		makeRequest(apiPaths.updateDrafts(data.id), "put", data),
	updateBizDrafts: data =>
		makeRequest(apiPaths.updateBizDrafts(data.id), "put", data),
	getProjectBiz: data =>request(`/project-biz/${data.id}`, {
			method: "post"
		 }),
	addBizDrafts: data => makeRequest(apiPaths.addBizDrafts, "post", data),
	delDrafts: projectId => makeRequest(apiPaths.delDrafts(projectId), "delete"),
	getProjectDetail: projectId =>
		makeRequest(apiPaths.getProjectDetail(projectId), "get"),
	getShowAmount: () => makeRequest(apiPaths.getShowAmount, "get"),
	apiGetDepart: data => makeRequest(apiPaths.apiGetDepart, "get", data),
	apiGetSetting: data => makeRequest(apiPaths.apiGetSetting, "get", data),
	apiGetProjTypeList: data =>
		makeRequest(apiPaths.apiGetProjTypeList, "get", data),
	apiGetProjFormList: data =>
		makeRequest(apiPaths.apiGetProjFormList, "get", data),
	apiAddFormType: data => makeRequest(apiPaths.apiAddFormType, "post", data),
	apiEditFormType: data =>
		makeRequest(apiPaths.apiEditFormType(data.projectConfigId), "put", data),
	apiDelFormType: projectConfigId =>
		makeRequest(apiPaths.apiDelFormType(projectConfigId), "delete"),
	apiCopyFormSetting: data =>
		makeRequest(
			apiPaths.apiCopyFormSetting(data.projectConfigId),
			"post",
			data
		),
	apiGetFormSetting: projectConfigId =>
		makeRequest(apiPaths.apiGetFormSetting(projectConfigId), "get"),
  apiSetFormSetting: data =>
    makeRequest(apiPaths.apiSetFormSetting(data.projectConfigId), "put", data),
  apiSetFormSettingConfig: data =>
		makeRequest(apiPaths.apiSetFormSettingConfig(data.projectConfigId), "put", data),
	apiGetSearchList: () => makeRequest(apiPaths.apiGetSearchList, "get"),
	apiGetProjectStepInfo: () =>
		makeRequest(apiPaths.apiGetProjectStepInfo, "get"),
	apiGetProcessStepInfo: () =>
		makeRequest(apiPaths.apiGetProcessStepInfo, "get"),
	apiTerminateProject: (projectId, data) =>
		makeRequest(apiPaths.apiTerminateProject(projectId), "post", data),
	apiGetRepeatPrjName: data =>
		makeRequest(apiPaths.apiGetRepeatPrjName, "get", data),
	apiGetFollowCheck: data =>
		makeRequest(apiPaths.apiGetFollowCheck(data.projectId), "get", data),
	apiSetFollowCheck: data =>
		makeRequest(apiPaths.apiSetFollowCheck, "post", data),
	apiGetCode: data => makeRequest(apiPaths.apiGetCode(data.projectId), "get"),
	apiUpdateCode: data => makeRequest(apiPaths.apiUpdateCode, "post", data)
}
