<template>
	<!--  <a-locale-provider :locale="locale">-->
	<div id="app">
		<router-view></router-view>
	</div>
	<!--  </a-locale-provider>-->
</template>
<script>
// 引入ant-design中文包
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN"
import "@/globalStyle/global.less"
import { mapMutations } from "vuex"

export default {
	data() {
		return {
			locale: zhCN
		}
	},
	computed: {},
	created() {
		// 删除loading动画
		document.body.removeChild(document.getElementById("appLoading"))
		document.body.style.backgroundColor = "#fff"
		setTimeout(() => {
			document.getElementById("app").style.display = "block"
		}, 500)
		// 监听窗口大小变化
		window.onresize = () => {
			this.updateState({
				// contentHeight: document.documentElement.clientHeight - 136
			})
		}
	},
	methods: {
		...mapMutations(["updateState"])
	}
}
</script>
