export default [
	"DELETE",
	"NOTICE",
	"NOTICE_PUBLISH",
	"CFDA_TYPE",
	"PROJECT_STATUS",
	"DOCFILE_STYLE",
	"MEETING_STATUS",
	"SITE_CATEGORY",
	"TRIAL_TYPE",
	"LEADER_STYLE",
	"TRAINING_CATG",
	"TRAINING_WAY",
	"RESPONSIBILITY",
	"RESEARCHSTAGE",
	"ETHICALSITUATION",
	"RESEARCHTYPE",
	"FINANCIALSITUATION",
	"PROVIDESERVICE",
	"CRU_SERVE",
	"CRU_SERVE_1",
	"CRU_SERVE_2",
	"CRU_SERVE_3",
	"RESEARCHTYPE_1",
	"HAND_BOOK_CONFIG"
]
